/* @group Base */
.chzn-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  zoom: 1;
  *display: inline;
}
.chzn-container .chzn-drop {
  position: absolute;
  top: 100%;
  left: -9999px;
  z-index: 1010;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #aaa;
  border-top: 0;
  background: #fff;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}
.chzn-container.chzn-with-drop .chzn-drop {
  left: 0;
}

/* @end */
/* @group Single Chosen */
.chzn-container-single .chzn-single {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0 0 0 8px;
  height: 23px;
  border: 1px solid #aaa;
  border-radius: 5px;
  background-color: #fff;
  background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(20%, #ffffff), color-stop(50%, #f6f6f6), color-stop(52%, #eeeeee), color-stop(100%, #f4f4f4));
  background: -webkit-linear-gradient(top, #ffffff 20%, #f6f6f6 50%, #eeeeee 52%, #f4f4f4 100%);
  background: -moz-linear-gradient(top, #ffffff 20%, #f6f6f6 50%, #eeeeee 52%, #f4f4f4 100%);
  background: -o-linear-gradient(top, #ffffff 20%, #f6f6f6 50%, #eeeeee 52%, #f4f4f4 100%);
  background: linear-gradient(top, #ffffff 20%, #f6f6f6 50%, #eeeeee 52%, #f4f4f4 100%);
  background-clip: padding-box;
  box-shadow: 0 0 3px white inset, 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #444;
  text-decoration: none;
  white-space: nowrap;
  line-height: 24px;
}
.chzn-container-single .chzn-default {
  color: #999;
}
.chzn-container-single .chzn-single span {
  display: block;
  overflow: hidden;
  margin-right: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chzn-container-single .chzn-single-with-deselect span {
  margin-right: 38px;
}
.chzn-container-single .chzn-single abbr {
  position: absolute;
  top: 6px;
  right: 26px;
  display: block;
  width: 12px;
  height: 12px;
  background: url(/assets2/css/img/chosen-sprite-8e70d120437ffc6a1bf7cebeca292d5c.png) -42px 1px no-repeat;
  font-size: 1px;
}
.chzn-container-single .chzn-single abbr:hover {
  background-position: -42px -10px;
}
.chzn-container-single.chzn-disabled .chzn-single abbr:hover {
  background-position: -42px -10px;
}
.chzn-container-single .chzn-single div {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 18px;
  height: 100%;
}
.chzn-container-single .chzn-single div b {
  display: block;
  width: 100%;
  height: 100%;
  background: url(/assets2/css/img/chosen-sprite-8e70d120437ffc6a1bf7cebeca292d5c.png) no-repeat 0px 2px;
}
.chzn-container-single .chzn-search {
  position: relative;
  z-index: 1010;
  margin: 0;
  padding: 3px 4px;
  white-space: nowrap;
}
.chzn-container-single .chzn-search input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 1px 0;
  padding: 4px 20px 4px 5px;
  width: 100%;
  outline: 0;
  border: 1px solid #aaa;
  background: white url(/assets2/css/img/chosen-sprite-8e70d120437ffc6a1bf7cebeca292d5c.png) no-repeat 100% -20px;
  background: url(/assets2/css/img/chosen-sprite-8e70d120437ffc6a1bf7cebeca292d5c.png) no-repeat 100% -20px, -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(1%, #eeeeee), color-stop(15%, #ffffff));
  background: url(/assets2/css/img/chosen-sprite-8e70d120437ffc6a1bf7cebeca292d5c.png) no-repeat 100% -20px, -webkit-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background: url(/assets2/css/img/chosen-sprite-8e70d120437ffc6a1bf7cebeca292d5c.png) no-repeat 100% -20px, -moz-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background: url(/assets2/css/img/chosen-sprite-8e70d120437ffc6a1bf7cebeca292d5c.png) no-repeat 100% -20px, -o-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background: url(/assets2/css/img/chosen-sprite-8e70d120437ffc6a1bf7cebeca292d5c.png) no-repeat 100% -20px, linear-gradient(#eeeeee 1%, #ffffff 15%);
  font-size: 1em;
  font-family: sans-serif;
}
.chzn-container-single .chzn-drop {
  margin-top: -1px;
  border-radius: 0 0 4px 4px;
  background-clip: padding-box;
}
.chzn-container-single.chzn-container-single-nosearch .chzn-search {
  position: absolute;
  left: -9999px;
}

/* @end */
/* @group Multi Chosen */
.chzn-container-multi .chzn-choices {
  position: relative;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto !important;
  height: 1%;
  border: 1px solid #aaa;
  background-color: #fff;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(1%, #eeeeee), color-stop(15%, #ffffff));
  background-image: -webkit-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background-image: -moz-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background-image: -o-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background-image: linear-gradient(#eeeeee 1%, #ffffff 15%);
  cursor: text;
}
.chzn-container-multi .chzn-choices li {
  float: left;
  list-style: none;
}
.chzn-container-multi .chzn-choices li.search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.chzn-container-multi .chzn-choices li.search-field input {
  margin: 1px 0;
  padding: 5px;
  height: 15px;
  outline: 0;
  border: 0 !important;
  background: transparent !important;
  box-shadow: none;
  color: #666;
  font-size: 100%;
  font-family: sans-serif;
}
.chzn-container-multi .chzn-choices li.search-field .default {
  color: #999;
}
.chzn-container-multi .chzn-choices li.search-choice {
  position: relative;
  margin: 3px 0 3px 5px;
  padding: 3px 20px 3px 5px;
  border: 1px solid #aaa;
  border-radius: 3px;
  background-color: #e4e4e4;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), color-stop(100%, #eeeeee));
  background-image: -webkit-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  background-image: -moz-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  background-image: -o-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  background-clip: padding-box;
  box-shadow: 0 0 2px white inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  color: #333;
  line-height: 13px;
  cursor: default;
}
.chzn-container-multi .chzn-choices li.search-choice .search-choice-close {
  position: absolute;
  top: 4px;
  right: 3px;
  display: block;
  width: 12px;
  height: 12px;
  background: url(/assets2/css/img/chosen-sprite-8e70d120437ffc6a1bf7cebeca292d5c.png) -42px 1px no-repeat;
  font-size: 1px;
}
.chzn-container-multi .chzn-choices li.search-choice .search-choice-close:hover {
  background-position: -42px -10px;
}
.chzn-container-multi .chzn-choices li.search-choice-disabled {
  padding-right: 5px;
  border: 1px solid #ccc;
  background-color: #e4e4e4;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), color-stop(100%, #eeeeee));
  background-image: -webkit-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  background-image: -moz-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  background-image: linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eeeeee 100%);
  color: #666;
}
.chzn-container-multi .chzn-choices li.search-choice-focus {
  background: #d4d4d4;
}
.chzn-container-multi .chzn-choices li.search-choice-focus .search-choice-close {
  background-position: -42px -10px;
}
.chzn-container-multi .chzn-results {
  margin: 0;
  padding: 0;
}
.chzn-container-multi .chzn-drop .result-selected {
  display: list-item;
  color: #ccc;
  cursor: default;
}
.chzn-container-multi .chzn-drop .result-selected em {
  background: transparent;
}

/* @end */
/* @group Results */
.chzn-container .chzn-results {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 4px 4px 0;
  padding: 0 0 0 4px;
  max-height: 240px;
  -webkit-overflow-scrolling: touch;
}
.chzn-container .chzn-results li {
  display: none;
  margin: 0;
  padding: 5px 6px;
  list-style: none;
  line-height: 15px;
}
.chzn-container .chzn-results li.active-result {
  display: list-item;
  cursor: pointer;
}
.chzn-container .chzn-results li.disabled-result {
  display: list-item;
  color: #ccc;
  cursor: default;
}
.chzn-container .chzn-results li.disabled-result em {
  background: transparent;
}
.chzn-container .chzn-results li.highlighted {
  background-color: #3875d7;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(20%, #3875d7), color-stop(90%, #2a62bc));
  background-image: -webkit-linear-gradient(#3875d7 20%, #2a62bc 90%);
  background-image: -moz-linear-gradient(#3875d7 20%, #2a62bc 90%);
  background-image: -o-linear-gradient(#3875d7 20%, #2a62bc 90%);
  background-image: linear-gradient(#3875d7 20%, #2a62bc 90%);
  color: #fff;
}
.chzn-container .chzn-results li.highlighted em {
  background: transparent;
}
.chzn-container .chzn-results li.no-results {
  display: list-item;
  background: #f4f4f4;
}
.chzn-container .chzn-results li.group-result {
  color: #999;
  font-weight: bold;
  cursor: default;
}
.chzn-container .chzn-results li.group-option {
  padding-left: 15px;
}
.chzn-container .chzn-results li em {
  background: #feffde;
  font-style: normal;
}

/* @end */
/* @group Active  */
.chzn-container-active .chzn-single {
  border: 1px solid #5897fb;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.chzn-container-active.chzn-with-drop .chzn-single {
  border: 1px solid #aaa;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(20%, #eeeeee), color-stop(80%, #ffffff));
  background-image: -webkit-linear-gradient(#eeeeee 20%, #ffffff 80%);
  background-image: -moz-linear-gradient(#eeeeee 20%, #ffffff 80%);
  background-image: -o-linear-gradient(#eeeeee 20%, #ffffff 80%);
  background-image: linear-gradient(#eeeeee 20%, #ffffff 80%);
  box-shadow: 0 1px 0 #fff inset;
}
.chzn-container-active.chzn-with-drop .chzn-single div {
  border-left: none;
  background: transparent;
}
.chzn-container-active.chzn-with-drop .chzn-single div b {
  background-position: -18px 2px;
}
.chzn-container-active .chzn-choices {
  border: 1px solid #5897fb;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.chzn-container-active .chzn-choices li.search-field input {
  color: #111 !important;
}

/* @end */
/* @group Disabled Support */
.chzn-disabled {
  opacity: 0.5 !important;
  cursor: default;
}
.chzn-disabled .chzn-single {
  cursor: default;
}
.chzn-disabled .chzn-choices .search-choice .search-choice-close {
  cursor: default;
}

/* @end */
/* @group Right to Left */
.chzn-rtl {
  text-align: right;
}
.chzn-rtl .chzn-single {
  overflow: visible;
  padding: 0 8px 0 0;
}
.chzn-rtl .chzn-single span {
  margin-right: 0;
  margin-left: 26px;
  direction: rtl;
}
.chzn-rtl .chzn-single-with-deselect span {
  margin-left: 38px;
}
.chzn-rtl .chzn-single div {
  right: auto;
  left: 3px;
}
.chzn-rtl .chzn-single abbr {
  right: auto;
  left: 26px;
}
.chzn-rtl .chzn-choices li {
  float: right;
}
.chzn-rtl .chzn-choices li.search-field input {
  direction: rtl;
}
.chzn-rtl .chzn-choices li.search-choice {
  margin: 3px 5px 3px 0;
  padding: 3px 5px 3px 19px;
}
.chzn-rtl .chzn-choices li.search-choice .search-choice-close {
  right: auto;
  left: 4px;
}
.chzn-rtl.chzn-container-single-nosearch .chzn-search,
.chzn-rtl .chzn-drop {
  left: 9999px;
}
.chzn-rtl.chzn-container-single .chzn-results {
  margin: 0 0 4px 4px;
  padding: 0 4px 0 0;
}
.chzn-rtl .chzn-results li.group-option {
  padding-right: 15px;
  padding-left: 0;
}
.chzn-rtl.chzn-container-active.chzn-with-drop .chzn-single div {
  border-right: none;
}
.chzn-rtl .chzn-search input {
  padding: 4px 5px 4px 20px;
  background: white url(/assets2/css/img/chosen-sprite-8e70d120437ffc6a1bf7cebeca292d5c.png) no-repeat -30px -20px;
  background: url(/assets2/css/img/chosen-sprite-8e70d120437ffc6a1bf7cebeca292d5c.png) no-repeat -30px -20px, -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(1%, #eeeeee), color-stop(15%, #ffffff));
  background: url(/assets2/css/img/chosen-sprite-8e70d120437ffc6a1bf7cebeca292d5c.png) no-repeat -30px -20px, -webkit-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background: url(/assets2/css/img/chosen-sprite-8e70d120437ffc6a1bf7cebeca292d5c.png) no-repeat -30px -20px, -moz-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background: url(/assets2/css/img/chosen-sprite-8e70d120437ffc6a1bf7cebeca292d5c.png) no-repeat -30px -20px, -o-linear-gradient(#eeeeee 1%, #ffffff 15%);
  background: url(/assets2/css/img/chosen-sprite-8e70d120437ffc6a1bf7cebeca292d5c.png) no-repeat -30px -20px, linear-gradient(#eeeeee 1%, #ffffff 15%);
  direction: rtl;
}
.chzn-rtl.chzn-container-single .chzn-single div b {
  background-position: 6px 2px;
}
.chzn-rtl.chzn-container-single.chzn-with-drop .chzn-single div b {
  background-position: -12px 2px;
}

