.jq-checkbox {
	background-image: url(/assets2/css/img/icon_input-b0edf7150679c530c3e678af51968c37.svg);
	background-size:contain;
	background-repeat: no-repeat;
	-webkit-font-smoothing: antialiased;
	height: 18px;
	width: 18px;
	cursor: pointer;
	vertical-align: top;
	-moz-user-select: none;
	position: relative;
	display: inline-block;
	overflow: hidden;
}
.black .jq-checkbox {
	background-image: url(/assets2/css/img/icon_input_black-ed960c98af793f42d1878736a82e69f1.svg);
	background-size:contain;
	height: 18px;
	width: 18px;
}
.jq-checkbox.checked .jq-checkbox__div {
	width: 18px;
	height: 18px;
	border-radius: 0;
	background: url(/assets2/css/img/icon_input_checked-200ae496cde548d115a5d13de438cec0.svg);
	background-size:contain;
	display: block;
}
.black .jq-checkbox.checked .jq-checkbox__div{
	background: url(/assets2/css/img/icon_input_checked_black-69451e7ec7493b05c6af6839d1191de2.svg);
	height: 18px;
	width: 18px;
	margin:0;
}
.jq-checkbox.focused {
	border: none;
}
.jq-checkbox.disabled {
	opacity: .55;
}


.jq-radio {
	top: -1px;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	margin: 0 4px 0 0;
	border: 1px solid #ECECEC;
	border-radius: 50%;
	background: #F5F5F5;
	background: -webkit-linear-gradient(#FFF, #E6E6E6);
	background:         linear-gradient(#FFF, #E6E6E6);
	box-shadow: 0 1px 1px rgba(0,0,0,.05), inset -1px -1px #FFF, inset 1px -1px #FFF;
	cursor: pointer;
}
.jq-radio.checked .jq-radio__div {
	width: 10px;
	height: 10px;
	margin: 3px 0 0 3px;
	border-radius: 50%;
	background: #777;
	box-shadow: inset 1px 1px 1px rgba(0,0,0,.7);
}
.jq-radio.focused {
	border: 1px solid #08C;
}
.jq-radio.disabled {
	opacity: .55;
}


.jq-file {
	width: 270px;
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(0,0,0,.1);
}
.jq-file input {
	height: auto;
	line-height: 1em;
	cursor: pointer;
}
.jq-file__name {
	overflow: hidden;
	box-sizing: border-box;
	width: 100%;
	height: 34px;
	padding: 0 80px 0 10px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	background: #FFF;
	box-shadow: inset 1px 1px #F1F1F1;
	font: 14px/32px Arial, sans-serif;
	color: #333;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.jq-file__browse {
	position: absolute;
	top: 1px;
	right: 1px;
	padding: 0 10px;
	border-left: 1px solid #CCC;
	border-radius: 0 4px 4px 0;
	background: #F5F5F5;
	background: -webkit-linear-gradient(#FFF, #E6E6E6);
	background:         linear-gradient(#FFF, #E6E6E6);
	box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px/32px Arial, sans-serif;
	color: #333;
	text-shadow: 1px 1px #FFF;
}
.jq-file:hover .jq-file__browse {
	background: #E6E6E6;
	background: -webkit-linear-gradient(#F6F6F6, #E6E6E6);
	background:         linear-gradient(#F6F6F6, #E6E6E6);
}
.jq-file:active .jq-file__browse {
	background: #F5F5F5;
	box-shadow: inset 1px 1px 3px #DDD;
}
.jq-file.focused .jq-file__name {
	border: 1px solid #5794BF;
}
.jq-file.disabled,
.jq-file.disabled .jq-file__name,
.jq-file.disabled .jq-file__browse {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}


.jq-number {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	padding: 0 36px 0 0;
}
.jq-number__field {
	width: 100px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
}
.jq-number__field:hover {
	border-color: #B3B3B3;
}
.jq-number__field input {
	box-sizing: border-box;
	width: 100%;
	padding: 8px 9px;
	border: none;
	outline: none;
	background: none;
	font: 14px Arial, sans-serif;
	color: #333;
	text-align: left; /* для Opera Presto */
	-moz-appearance: textfield;
}
.jq-number__field input::-webkit-inner-spin-button,
.jq-number__field input::-webkit-outer-spin-button {
	margin: 0; /* в каких-то браузерах присутствует отступ */
	-webkit-appearance: none;
}
.jq-number__spin {
	position: absolute;
	top: 0;
	right: 0;
	width: 32px;
	height: 14px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	background: #F5F5F5;
	background: -webkit-linear-gradient(#FFF, #E6E6E6);
	background:         linear-gradient(#FFF, #E6E6E6);
	box-shadow: 0 1px 2px rgba(0,0,0,.1);
	box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-shadow: 1px 1px #FFF;
	cursor: pointer;
}
.jq-number__spin.minus {
	top: auto;
	bottom: 0;
}
.jq-number__spin:hover {
	background: #E6E6E6;
	background: -webkit-linear-gradient(#F6F6F6, #E6E6E6);
	background:         linear-gradient(#F6F6F6, #E6E6E6);
}
.jq-number__spin:active {
	background: #F5F5F5;
	box-shadow: inset 1px 1px 3px #DDD;
}
.jq-number__spin:after {
	content: '';
	position: absolute;
	top: 4px;
	left: 11px;
	width: 0;
	height: 0;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #999;
	border-left: 5px solid transparent;
}
.jq-number__spin.minus:after {
	top: 5px;
	border-top: 5px solid #999;
	border-right: 5px solid transparent;
	border-bottom: none;
	border-left: 5px solid transparent;
}
.jq-number__spin.minus:hover:after {
	border-top-color: #000;
}
.jq-number__spin.plus:hover:after {
	border-bottom-color: #000;
}
.jq-number.focused .jq-number__field {
	border: 1px solid #5794BF;
}
.jq-number.disabled .jq-number__field,
.jq-number.disabled .jq-number__spin {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-number.disabled .jq-number__spin:after {
	border-bottom-color: #AAA;
}
.jq-number.disabled .jq-number__spin.minus:after {
	border-top-color: #AAA;
}


.jq-selectbox {
	vertical-align: middle;
	cursor: pointer;
	text-align: left;
	height: 40px;
	line-height: 40px;
}
.top-rating .jq-selectbox{
	margin: 0 0 0 10px;
}
.list-pager .jq-selectbox{
	margin-right:10px;
	margin-bottom: 10px;
	float: left;
}
.jq-selectbox__select {
	border-radius: 5px;
	color: #474747;
	font-weight: 300;
	font-family: TT Hoves, sans-serif;
	border:1px solid #ECECEC;
	height: 40px;
	line-height: 40px;
	white-space: nowrap;
	font-size: 12px;
	text-align: left;
	display: inline-block;
	position: relative;
	overflow: hidden;
	background:#fff;
	width:200px;
	z-index: 100000;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	text-align: left;
	overflow: hidden;
	box-shadow: none !important;
	margin:0;
	padding: 0;
}
.columns-left-nav .jq-selectbox__select{
	width:212px;
}
.top-rating .jq-selectbox__select{
	height:40px;
	line-height: 40px;
	min-width:110px!important;
	width:110px!important;
	margin: 0;
}
.promo .jq-selectbox__select {
	width:100%;
	top:0px;
	display: inline-block;
}
.promo.video .jq-selectbox__select {
	width: 320px;
}

.block-user-info .jq-selectbox__select {
	width:209px;
}

.list-pager .jq-selectbox__select {
	white-space: nowrap;
	width:85px!important;
}
.block-banner .jq-selectbox__select{
	width:100px;
}

.choose-subid .jq-selectbox__select,
.period-reg .jq-selectbox__select {
}
.jq-selectbox.disabled .jq-selectbox__select {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-selectbox__select-text {
	display: block;
	overflow: hidden;
	font-size: 12px;
	color:#474747;
	width:100%!important;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding:0 0 0 10px;
}
.icon-olymp  .jq-selectbox__select-text,
.icon-walbi  .jq-selectbox__select-text,
.icon-doto  .jq-selectbox__select-text{
	position: relative;
	padding: 0 0 0 40px;
	font-size: 14px;
}

.icon-olymp  .jq-selectbox__select-text:before{
	content:'';
	display:block;
	position: absolute;
	top:50%;
	left:10px;
	width:20px;
	height: 20px;
	background: url(/assets2/css/img/cabinet/icon_olymp-94300d17a649dc949e99b24810a26669.svg) no-repeat;
}

.icon-doto  .jq-selectbox__select-text:before{
	content:'';
	display:block;
	position: absolute;
	top:50%;
	left:10px;
	width:17px;
	height: 15px;
	background: url(/assets2/css/img/cabinet/icon_doto-3d627f26336ae1c7ab289191248af79f.svg) no-repeat;
	transform: translate(0, 3px);
}

.icon-walbi  .jq-selectbox__select-text:before{
	content:'';
	display:block;
	position: absolute;
	top:50%;
	left:10px;
	width:20px;
	height: 20px;
	background: url(/assets2/css/img/cabinet/icon_walbi-30897fd1885bf408d985f0863cea0d8b.svg) no-repeat;
}

.lang-ru .jq-selectbox__select-text,
.lang-en .jq-selectbox__select-text,
.lang-id .jq-selectbox__select-text,
.lang-hi .jq-selectbox__select-text,
.lang-es .jq-selectbox__select-text,
.lang-pt .jq-selectbox__select-text,
.lang-vi .jq-selectbox__select-text,
.lang-th .jq-selectbox__select-text
{
	display: block;
	position: relative;
	padding-left: 45px;
}
.jq-selectbox__select-text:before{
	content:'';
	display: inline-block;
	position: absolute;
	top:50%;
	left:10px;
	width:24px;
	height:16px;
	margin:-10px 0 0 0;
}
.lang-ru .jq-selectbox__select-text:before {
	 background: url(/assets2/css/img/icon_ru-2d393fc43098a9d526b730a0492c1dc4.svg) center center no-repeat;
	background-size: contain;
 }
.lang-en .jq-selectbox__select-text:before {
	 background: url(/assets2/css/img/icon_en-fa7876040bd744ff2440917d513eaea3.svg) center center no-repeat;
	background-size: contain;
 }
.lang-id .jq-selectbox__select-text:before {
	 background: url(/assets2/css/img/icon_id-a612b03457b98e9f40709beb5ff3a13e.svg) center center no-repeat;
	background-size: contain;
 }
.lang-hi .jq-selectbox__select-text:before {
	background: url(/assets2/css/img/icon_hi-78ca43430806e78eae58eb4ca5952c69.svg) center center no-repeat;
	background-size: contain;
}
.lang-es .jq-selectbox__select-text:before {
	background: url(/assets2/css/img/icon_es-c7b09068ea7767e501eab41242219d19.svg) center center no-repeat;
	background-size: contain;
}
.lang-pt .jq-selectbox__select-text:before {
	background: url(/assets2/css/img/icon_pt-1477ee6f910f7c018bf81e665cca838b.svg) center center no-repeat;
	background-size: contain;
}
.lang-vi .jq-selectbox__select-text:before {
	 background: url(/assets2/css/img/icon_vi-57dbbda1317749a05867a40fc290792b.svg) center center no-repeat;
	 background-size: contain;
 }

.lang-th .jq-selectbox__select-text:before {
	 background: url(/assets2/css/img/icon_th-a57d2758db326308dcc7a249021fc433.svg) center center no-repeat;
	 background-size: contain;
 }
.jq-selectbox .placeholder {
	color: #888;
}
.jq-selectbox__trigger {
	position: absolute;
	top: 0;
	right: 0;
	width: 28px;
	height: 100%;
	background: #fff;
}

.jq-selectbox__dropdown {
	box-sizing: border-box;
	width: 100%;
	margin: -2px 0 0 0;
	padding: 0;
	color: #474747;
	border:  1px solid #828286;
	border-top:none;
	border-radius: 0 0 5px 5px;
	box-shadow: 0 11px 10px rgba(0, 0, 0, 0.2);
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	background: #fff;
	border-top: 0;
}
.black .jq-selectbox__select {
	background: #5D616B;
	border-radius: 0;
	border: 1px solid #474747;
	color: #9b9b9b;
	width: 196px;
 }
.black .jq-selectbox li.selected {
	color: #9b9b9b;
	background: #5D616B;
}
.black .jq-selectbox li:hover {
	background: #474747;
}
.black .opened .jq-selectbox__select {
	border-radius: 0;
}
.black .change-lang .icon-down::after,
.black .change-lang .icon-down::before,
.black .columns-left-nav-list-item-link .fa-icon-caret-down::after,
.black .columns-left-nav-list-item-link .fa-icon-caret-down::before,
.black .faq-item-list-item .arrow::after,
.black .faq-item-list-item .arrow::before,
.black .jq-selectbox__trigger-arrow::after,
.black .jq-selectbox__trigger-arrow::before,
.black .pay-system .arrow::after,
.black .pay-system .arrow::before,
.black .support .item-ticket .item-title-ticket .arrow::after,
.black .support .item-ticket .item-title-ticket .arrow::before {
	background: #9b9b9b;
}
.black .jq-selectbox__select-text {
	color: #9b9b9b;
}
.black .jq-selectbox__trigger {
	background: #5D616B;
}
.black .jq-selectbox__dropdown {
	background: #5D616B;
	color: #9b9b9b;
	border-radius: 0;
	border: 1px solid #474747;
}

.light .jq-selectbox__select {
	background: #fff;
	border-radius: 0;
	border: 1px solid #e0e2e5;
	color: #9b9b9b;
	width: 196px;
}
.light .jq-selectbox li.selected {
	color: #9b9b9b;
	background: #fff;
}
.light .jq-selectbox li:hover {
	background: #e0e2e5;
}
.light .jq-selectbox__trigger {
	background: #fff;
}
.light .jq-selectbox__dropdown {
	background: #fff;
	color: #9b9b9b;
	border-radius: 0;
	border: 1px solid #e0e2e5;
}


.list-pager .jq-selectbox__dropdown {
	color: #2a475a;
	border:  1px solid #e2e2e2;
	border-top:none;
	z-index: 10000;
	margin: 0;
}
.top-rating .jq-selectbox__dropdown{
	border:  1px solid #dcdcdc;
	margin:-2px 0 0 0;
}
.promo .jq-selectbox__dropdown{
	margin: 0;
	overflow: hidden;
}
.person-traffic .jq-selectbox__dropdown {
	max-height:140px!important;
}
.opened .jq-selectbox__select{
	-webkit-border-radius: 5px 5px 0px 0px;
	-moz-border-radius: 5px 5px 0px 0px;
	border-radius: 5px 5px 0px 0px;
	border:1px solid #828286;
}
.jq-selectbox__search {
	margin: 5px;
}
.jq-selectbox__search input {
	box-sizing: border-box;
	width: 100%;
	margin: 0;
	padding: 0 27px 0 8px;
	height:30px;
	border: 1px solid #CCC;
	border-radius: 3px;
	outline: none;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==) no-repeat 100% 50%;
	box-shadow: inset 1px 1px #F1F1F1;
	color: #333;
	-webkit-appearance: textfield;
}
.jq-selectbox__search input::-webkit-search-cancel-button,
.jq-selectbox__search input::-webkit-search-decoration {
	-webkit-appearance: none;
}
.jq-selectbox__not-found {
	margin: 5px;
	padding: 5px 8px 6px;
	background: #F0F0F0;
	font-size: 13px;
}
.jq-selectbox ul {
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}
.jq-selectbox ul::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 9px;
}
.jq-selectbox ul::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(210, 220, 225, .5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
	border:1px solid #fff;
}
.person-traffic .jq-selectbox ul {
	max-height:140px!important;
}
.jq-selectbox li {
	font-size: 12px;
	font-weight: 300;
	padding: 10px; /* отсуп в списках в Статистике не трогать */
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: nowrap;
}
.jq-selectbox li.icon-doto,
.jq-selectbox li.icon-walbi,
.jq-selectbox li.icon-olymp {
	position:relative;
	padding-left:40px;
	font-size: 14px;
}

.jq-selectbox li.icon-doto:before{
	content:'';
	display:block;
	position: absolute;
	top:50%;
	left:10px;
	width:17px;
	height: 15px;
	background: url(/assets2/css/img/cabinet/icon_doto-3d627f26336ae1c7ab289191248af79f.svg) no-repeat;
	transform: translate(0, -50%);
}

.jq-selectbox li.icon-olymp:before{
	content:'';
	display:block;
	position: absolute;
	top:50%;
	left:10px;
	width:20px;
	height: 20px;
	background: url(/assets2/css/img/cabinet/icon_olymp-94300d17a649dc949e99b24810a26669.svg) no-repeat;
	transform: translate(0, -50%);
}

.jq-selectbox li.icon-walbi:before{
	content:'';
	display:block;
	position: absolute;
	top:50%;
	left:10px;
	width:20px;
	height: 20px;
	background: url(/assets2/css/img/cabinet/icon_walbi-30897fd1885bf408d985f0863cea0d8b.svg) no-repeat;
	transform: translate(0, -50%);
}

.jq-selectbox li.selected,
.jq-selectbox li:hover{
	background-color: #ECECEC;
	color: #474747;
}
.jq-selectbox li:last-child.selected,
.jq-selectbox li:last-child:hover{
	border-radius: 0 0 5px 5px;
}
.jq-selectbox li.disabled {
	color: #AAA;
}
.jq-selectbox li.disabled:hover {
	background: none;
}
.jq-selectbox li.optgroup {
	font-weight: bold;
}
.jq-selectbox li.optgroup:hover {
	background: none;
	color: #231F20;
	cursor: default;
}
.jq-selectbox li.option {
	padding-left: 25px;
}


.jq-select-multiple {
	box-sizing: border-box;
	padding: 1px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	background: #FFF;
	box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px/18px Arial, sans-serif;
	color: #333;
	cursor: default;
}
.jq-select-multiple.focused {
	border: 1px solid #5794BF;
}
.jq-select-multiple.disabled {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-select-multiple ul {
	margin: 0;
	padding: 0;
}
.jq-select-multiple li {
	padding: 3px 9px 4px;
	list-style: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: nowrap;
}
.jq-select-multiple li:first-child {
	border-radius: 3px 3px 0 0;
}
.jq-select-multiple li:last-child {
	border-radius: 0 0 3px 3px;
}
.jq-select-multiple li.selected {
	background: #08C;
	color: #FFF;
}
.jq-select-multiple li.disabled {
	color: #AAA;
}
.jq-select-multiple.disabled li.selected,
.jq-select-multiple li.selected.disabled {
	background: #CCC;
	color: #FFF;
}
.jq-select-multiple li.optgroup {
	font-weight: bold;
}
.jq-select-multiple li.option {
	padding-left: 25px;
}


input[type='email'].styler,
input[type='password'].styler,
input[type='search'].styler,
input[type='tel'].styler,
input[type='text'].styler,
input[type='url'].styler,
textarea.styler {
	padding: 8px 9px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px Arial, sans-serif;
	color: #333;
}
input[type='search'].styler {
	-webkit-appearance: none;
}
textarea.styler {
	overflow: auto;
}
input[type='email'].styler:hover,
input[type='password'].styler:hover,
input[type='search'].styler:hover,
input[type='tel'].styler:hover,
input[type='text'].styler:hover,
input[type='url'].styler:hover,
textarea.styler:hover {
	border-color: #B3B3B3;
}
input[type='email'].styler:focus,
input[type='password'].styler:focus,
input[type='search'].styler:focus,
input[type='tel'].styler:focus,
input[type='text'].styler:focus,
input[type='url'].styler:focus,
textarea.styler:focus {
	border-color: #CCC;
	border-top-color: #B3B3B3;
	outline: none;
	box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}
button.styler,
input[type='button'].styler,
input[type='submit'].styler,
input[type='reset'].styler {
	overflow: visible;
	padding: 8px 11px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	outline: none;
	background: #F5F5F5;
	background: -webkit-linear-gradient(#FFF, #E6E6E6);
	background:         linear-gradient(#FFF, #E6E6E6);
	box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px Arial, sans-serif;
	color: #333;
	text-shadow: 1px 1px #FFF;
	cursor: pointer;
}
button.styler::-moz-focus-inner,
input[type='button'].styler::-moz-focus-inner,
input[type='submit'].styler::-moz-focus-inner,
input[type='reset'].styler::-moz-focus-inner {
	padding: 0;
	border: 0;
}
button.styler:not([disabled]):hover,
input[type='button'].styler:not([disabled]):hover,
input[type='submit'].styler:not([disabled]):hover,
input[type='reset'].styler:hover {
	background: #E6E6E6;
	background: -webkit-linear-gradient(#F6F6F6, #E6E6E6);
	background:         linear-gradient(#F6F6F6, #E6E6E6);
}
button.styler:not([disabled]):active,
input[type='button'].styler:not([disabled]):active,
input[type='submit'].styler:not([disabled]):active,
input[type='reset'].styler:active {
	background: #F5F5F5;
	box-shadow: inset 1px 1px 3px #DDD;
}
button.styler[disabled],
input[type='button'].styler[disabled],
input[type='submit'].styler[disabled] {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}

@media only screen and (max-width: 320px) {
	.promo.video .jq-selectbox__select {
		width:280px;
	}
}

