.SumoSelect p {
    margin: 0;
}

.SumoSelect {
    width: 100%;
}

.SelectBox {
    padding: 0 10px;
}

.sumoStopScroll {
    overflow: hidden;
}

/* Filtering style */
.SumoSelect .hidden {
    display: none;
}

.SumoSelect .search-txt ,
.SumoSelect .search-icon {
    display: none;
    outline: none;
}

.SumoSelect .no-match {
    display: none;
    padding: 6px;
}

.SumoSelect.open .search-txt {
    display: inline-block;
    position: absolute;
    top: 50px;
    left: 0;
    width: 96%;
    margin: 0 2%;
    padding: 5px 8px;
    border: 1px solid #ECECEC;
    background: #FFFFFF;
    z-index: 100;
    height: 40px;
    border-radius: 3px;
}
.SumoSelect.open .search-icon {
    background-image: url(/assets2/css/img/icon_search-cd36217c910ea404208317bbcac973df.svg);
    background-position: center center;
    width: 14px;
    height: 14px;
    display: block;
    position: absolute;
    top: 62px;
    right: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1000;
}

/*this is applied on that hidden select. DO NOT USE display:none; or visiblity:hidden; and Do not override any of these properties. */
.SelectClass, .SumoUnder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
}

.SumoUnder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
}

.SelectClass {
    z-index: 1;
}

.SumoSelect > .optWrapper > .options li.opt label, .SumoSelect > .CaptionCont, .SumoSelect .select-all > label {
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.multiple-select-advert ~ .optWrapper > .options li.opt label{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.SumoSelect {
    display: inline-block;
    position: relative;
    outline: none;
    text-align:center;
}

.SumoSelect > .CaptionCont {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #ECECEC;
    border-radius: 3px;
    background-color: #fff;
    height: 40px;
}

.SumoSelect.open > .CaptionCont {
    border-radius: 3px 3px 0 0;
}

.SumoSelect > .CaptionCont.multiple-select-advert {
    width:192px;
    margin: 20px auto 0 auto;
    border-radius: 2px;
    border: 1px solid #EAEAEA;
}
.SumoSelect.open > .CaptionCont.multiple-select-advert {
    border: 1px solid #828286;
    border-radius: 2px 2px 0 0;
    border-bottom:0;
}

.SumoSelect > .CaptionCont > img{
    margin:0 10px 0 0;
}

.SumoSelect > .CaptionCont > span {
    display: block;
    padding-right: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: default;
}

/*placeholder style*/
.SumoSelect > .CaptionCont > span.placeholder {
    color: #000000;
    line-height: 40px;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.01em;
}

.SumoSelect > .CaptionCont > label {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 30px;
}

.SumoSelect > .CaptionCont > label > i {
    background-image: url(/assets2/css/img/icon_arrow_gray_down-bf4827a98a7755e44faa1d8345ba7996.svg);
    background-position: center center;
    width: 14px;
    height: 14px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.8;
    transform: scale(1, 1);
    transition: transform 0.3s;
}

.SumoSelect.open > .CaptionCont > label > i {
    transform: scale(1, -1);
    transition: transform 0.3s;
}

.SumoSelect > .optWrapper {
    display: none;
    top: 42px;
    width: 100%;
    position: absolute;
    padding-top: 55px;
    left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #ECECEC;
    border-top: none;
    overflow: hidden;
    z-index: 10;
}

.SumoSelect.open > .optWrapper {
    top: 42px;
    display: block;
    border: 1px solid #828286;
}

.multiple-select-advert ~ .optWrapper{
    padding-top: 0;
    border-radius: 0 0 2px 2px;
}

.multiple-select-advert ~ .optWrapper{
    top: 60px!important;
    width: 213px;
    text-align: left;
    left: 11.5px;
    padding: 5px;
}

.SumoSelect.open > .optWrapper.up {
    top: auto;
    bottom: 100%;
    margin-bottom: 5px;
    border: 1px solid #828286;
}

.SumoSelect > .optWrapper ul {
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;
    overflow: auto;
}

.SumoSelect > .optWrapper > .options {
    border-radius: 2px;
    position: relative;
    /*Set the height of pop up here (only for desktop mode)*/
    max-height: 250px;
    /*height*/
}

.SumoSelect > .optWrapper.okCancelInMulti > .options {
    border-radius: 2px 2px 0 0;
}

.SumoSelect > .optWrapper.selall > .options {
    border-radius: 0 0 2px 2px;
}

.SumoSelect > .optWrapper.selall.okCancelInMulti > .options {
    border-radius: 0;
}

.SumoSelect > .optWrapper > .options li.group.disabled > label {
    opacity: 0.5;
}

.SumoSelect > .optWrapper > .options li ul li.opt {
    padding-left: 22px;
}

.SumoSelect > .optWrapper.multiple > .options li ul li.opt {
    padding-left: 50px;
}

.SumoSelect > .optWrapper.isFloating > .options {
    max-height: 100%;
    box-shadow: 0 0 100px #595959;
}

.SumoSelect > .optWrapper > .options li.opt {
    padding: 6px 6px;
    position: relative;
}

.SumoSelect > .optWrapper > .options > li.opt:first-child {
    border-radius: 2px 2px 0 0;
}

.SumoSelect > .optWrapper.selall > .options > li.opt:first-child {
    border-radius: 0;
}

.SumoSelect > .optWrapper > .options > li.opt:last-child {
    border-radius: 0 0 2px 2px;
    border-bottom: none;
}

.SumoSelect > .optWrapper.okCancelInMulti > .options > li.opt:last-child {
    border-radius: 0;
}

.SumoSelect > .optWrapper > .options li.opt:hover {
    background-color: #E4E4E4;
}

.SumoSelect > .optWrapper > .options li.opt.sel, .SumoSelect .select-all.sel {
    background-color: #a1c0e4;
}

.SumoSelect > .optWrapper > .options li label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    cursor: pointer;
}

.SumoSelect > .optWrapper > .options li span {
    display: none;
}

.SumoSelect > .optWrapper > .options li.group > label {
    cursor: default;
    padding: 8px 6px;
    font-weight: bold;
}

/*Floating styles*/
.SumoSelect > .optWrapper.isFloating {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 90%;
    bottom: 0;
    margin: auto;
    max-height: 90%;
}

/*disabled state*/
.SumoSelect > .optWrapper > .options li.opt.disabled {
    background-color: inherit;
    pointer-events: none;
}

.SumoSelect > .optWrapper > .options li.opt.disabled * {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; /* IE 5-7 */
    filter: alpha(opacity=50); /* Netscape */
    -moz-opacity: 0.5; /* Safari 1.x */
    -khtml-opacity: 0.5; /* Good browsers */
    opacity: 0.5;
}


/*styling for multiple select*/
.SumoSelect > .optWrapper.multiple > .options li.opt {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:12px 2%;
    cursor: pointer;
}
.SumoSelect > .optWrapper.multiple > .options li.opt.hidden{
    display: none;
}

.SumoSelect > .optWrapper.multiple > .options li.opt label{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap:8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #535353;
}

.SumoSelect > .optWrapper.multiple > .options li.opt img{
    width:16px;
    height:16px;
}

.SumoSelect > .optWrapper.multiple > .options li.opt span,
.SumoSelect .select-all > span {
    display:block;
    position: relative;
}

.SumoSelect > .optWrapper.multiple > .options li.opt span i,
.SumoSelect .select-all > span i {
    display: block;
    width: 14px;
    height: 14px;
    border: 2px solid #505054;
    background-color: #fff;
}

.SumoSelect > .optWrapper > .MultiControls {
    display: none;
    border-top: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
    border-radius: 0 0 3px 3px;
}

.SumoSelect > .optWrapper.multiple.isFloating > .MultiControls {
    display: block;
    margin-top: 5px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls {
    display: block;
}

.SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls > p {
    padding: 6px;
}

.SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls > p:focus {
    box-shadow: 0 0 2px #a1c0e4;
    border-color: #a1c0e4;
    outline: none;
    background-color: #a1c0e4;
}

.SumoSelect > .optWrapper.multiple > .MultiControls > p {
    display: inline-block;
    cursor: pointer;
    padding: 12px;
    width: 50%;
    box-sizing: border-box;
    text-align: center;
}

.SumoSelect > .optWrapper.multiple > .MultiControls > p:hover {
    background-color: #f1f1f1;
}

.SumoSelect > .optWrapper.multiple > .MultiControls > p.btnOk {
    border-right: 1px solid #DBDBDB;
    border-radius: 0 0 0 3px;
}

.SumoSelect > .optWrapper.multiple > .MultiControls > p.btnCancel {
    border-radius: 0 0 3px 0;
}

/*styling for select on popup mode*/
.SumoSelect > .optWrapper.isFloating > .options li.opt {
    padding: 12px 6px;
}

/*styling for only multiple select on popup mode*/
.SumoSelect > .optWrapper.multiple.isFloating > .options li.opt {
    padding-left: 35px;
}

.SumoSelect > .optWrapper.multiple.isFloating {
    padding-bottom: 43px;
}

.SumoSelect > .optWrapper.multiple > .options li.opt.selected span i,
.SumoSelect .select-all.selected > span i,
.SumoSelect .select-all.partial > span i {
    background-color: #292929;
    box-shadow: none;
    border-color: transparent;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABMSURBVAiZfc0xDkAAFIPhd2Kr1WRjcAExuIgzGUTIZ/AkImjSofnbNBAfHvzAHjOKNzhiQ42IDFXCDivaaxAJd0xYshT3QqBxqnxeHvhunpu23xnmAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: center center;
}

/*disabled state*/
.SumoSelect.disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.SumoSelect.disabled > .CaptionCont {
    border-color: #ccc;
    box-shadow: none;
}

/**Select all button**/
.SumoSelect .select-all {
    border-radius: 3px 3px 0 0;
    position: relative;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    padding: 8px 0 3px 35px;
    height: 20px;
    cursor: pointer;
}

.SumoSelect .select-all > label, .SumoSelect .select-all > span i {
    cursor: pointer;
}

.SumoSelect .select-all.partial > span i {
    background-color: #ccc;
}


/*styling for optgroups*/
.SumoSelect > .optWrapper > .options li.optGroup {
    padding-left: 5px;
    text-decoration: underline;
}


.reset-all {
    padding: 5px 10px 5px 35px;
    text-align: right;
}

.SumoSelect[is-selected="false"] .reset-all {
    display: none;
    opacity: 0.5;
    pointer-events: none;
}

.SumoSelect[is-selected="true"] .reset-all {
    display: block;
    opacity: 1;
    cursor: pointer;
    color: #505054;
    font-size: 12px;
    line-height: 18px;
}
.SumoSelect[is-selected="true"] .reset-all span{
    position: relative;
}
.SumoSelect[is-selected="true"] .reset-all span:before {
    content: '';
    display: block;
    position: absolute;
    left:-10px;
    top:0;
    width:7px;
    height: 7px;
    background-image: url(/assets2/css/img/icon_close_gray-3496acfa83ffe9ce08c882fd1cebc72e.svg);
    transform: translate(0, 50%);
}

.SumoSelect[is-selected="true"] .reset-all * {
    cursor: pointer;
}

.SumoSelect[is-selected="true"] .reset-all:hover {
    color: black;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}