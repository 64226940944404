.iPhoneCheckContainer {
  position: relative;
  height: 27px;
  cursor: pointer;
  overflow: hidden; }
  .iPhoneCheckContainer input {
    position: absolute;
    top: 5px;
    left: 30px;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  .iPhoneCheckContainer label {
    white-space: nowrap;
    font-size: 17px;
    line-height: 17px;
    font-weight: bold;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    cursor: pointer;
    display: block;
    height: 27px;
    position: absolute;
    width: auto;
    top: 0;
    padding-top: 5px;
    overflow: hidden; }
  .iPhoneCheckContainer, .iPhoneCheckContainer label {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none; }

.iPhoneCheckDisabled {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5; }

label.iPhoneCheckLabelOn {
  color: white;
  background: url(/assets2/css/img/iphone-style-checkboxes/on-2df2821fd6e6db8dec8fac1b0b1e9dca.png) no-repeat;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
  left: 0;
  padding-top: 5px; }
  label.iPhoneCheckLabelOn span {
    padding-left: 8px; }
label.iPhoneCheckLabelOff {
  color: #8b8b8b;
  background: url(/assets2/css/img/iphone-style-checkboxes/off-0b6ae98ce385f4a29240cf8eaead4a04.png) no-repeat right 0;
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.6);
  text-align: right;
  right: 0; }
  label.iPhoneCheckLabelOff span {
    padding-right: 8px; }

.iPhoneCheckHandle {
  display: block;
  height: 27px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  background: url(/assets2/css/img/iphone-style-checkboxes/slider_left-43f641db68d057de7da888fb9f0c6772.png) no-repeat;
  padding-left: 3px; }

.iPhoneCheckHandleRight {
  height: 100%;
  width: 100%;
  padding-right: 3px;
  background: url(/assets2/css/img/iphone-style-checkboxes/slider_right-4acf3771ab3ed02018525a27c146bfdc.png) no-repeat right 0; }

.iPhoneCheckHandleCenter {
  height: 100%;
  width: 100%;
  background: url(/assets2/css/img/iphone-style-checkboxes/slider_center-79c16c67d2dc8c4dace5e325b0775809.png); }

.iOSCheckContainer {
  position: relative;
  height: 27px;
  cursor: pointer;
  overflow: hidden; }
  .iOSCheckContainer input {
    position: absolute;
    top: 5px;
    left: 30px;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  .iOSCheckContainer label {
    white-space: nowrap;
    font-size: 17px;
    line-height: 17px;
    font-weight: bold;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    cursor: pointer;
    display: block;
    height: 27px;
    position: absolute;
    width: auto;
    top: 0;
    padding-top: 5px;
    overflow: hidden; }
  .iOSCheckContainer, .iOSCheckContainer label {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none; }

.iOSCheckDisabled {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5; }

label.iOSCheckLabelOn {
  color: white;
  background: url(/assets2/css/img/iphone-style-checkboxes/on-2df2821fd6e6db8dec8fac1b0b1e9dca.png) no-repeat;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
  left: 0;
  padding-top: 5px; }
  label.iOSCheckLabelOn span {
    padding-left: 8px; }
label.iOSCheckLabelOff {
  color: #8b8b8b;
  background: url(/assets2/css/img/iphone-style-checkboxes/off-0b6ae98ce385f4a29240cf8eaead4a04.png) no-repeat right 0;
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.6);
  text-align: right;
  right: 0; }
  label.iOSCheckLabelOff span {
    padding-right: 8px; }

.iOSCheckHandle {
  display: block;
  height: 27px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  background: url(/assets2/css/img/iphone-style-checkboxes/slider_left-43f641db68d057de7da888fb9f0c6772.png) no-repeat;
  padding-left: 3px; }

.iOSCheckHandleRight {
  height: 100%;
  width: 100%;
  padding-right: 3px;
  background: url(/assets2/css/img/iphone-style-checkboxes/slider_right-4acf3771ab3ed02018525a27c146bfdc.png) no-repeat right 0; }

.iOSCheckHandleCenter {
  height: 100%;
  width: 100%;
  background: url(/assets2/css/img/iphone-style-checkboxes/slider_center-79c16c67d2dc8c4dace5e325b0775809.png); }

/* Localized */