
table.tablesorter thead tr .header p:after{
    content: '';
    display: block;
    position: absolute;
    right: -15px;
    top: 0px;
    background: url(/assets2/css/images/cabinet/stat_arrow-74969b2983085d8e1b4135e535a3c386.jpg) no-repeat center;
    cursor: pointer;
    width: 8px;
    height: 15px;
}
table.tablesorter thead tr .headerSortDown p:after{
    content: '';
    display: block;
    position: absolute;
    right: -15px;
    top: 5px;
    background: url(/assets2/css/images/cabinet/stat_up_arrow-67c0e3a130bb5fdcb6cc4018f7529dcb.jpg) no-repeat center;
    cursor: pointer;
    width: 8px;
    height: 6px
}
table.tablesorter thead tr .headerSortUp p:after{
    content: '';
    display: block;
    position: absolute;
    right: -15px;
    top: 10px;
    background: url(/assets2/css/images/cabinet/stat_down_arrow-27d6b56d3fe87d3813eae33f6af03cef.jpg) no-repeat center;
    cursor: pointer;
    width: 8px;
    height: 6px
}