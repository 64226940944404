html {height: 100%;}
* html body {height: 100%;}

a.sprite{
    text-decoration:none;
    color: #333;
}
a:hover.sprite{
    text-decoration:none;
    color: #333;
}

a:hover.grad-lgrey{
    text-decoration:none;
    color: #333;
}

@font-face {
    font-family: symbol-olymp;
    src: local("symbol-olymp"),url("/fonts/symbol-olymp.woff");
}

a {
    text-decoration:none;
    color: #FFCD00;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
}
ul.list{
    margin-left: 15px;
    margin-bottom: 18px;
}
ul.list li{
    list-style-type: square;
    margin-bottom: 9px;
}
p a{
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}
#page{
    text-align:center;
}
/*---footer hack---*/
#page{padding-bottom-: 244px;}




/*--/-footer hack---*/
/*---tpl width settings---*/
header .header-wrap,
footer .footer-wrap,
#content{
    max-width: 982px;
    margin:0 auto;
    text-align:left;
}

/*---/tpl width settings---*/

.text-page h1{
    font-size: 2.25em;
    color: #999;
    margin-bottom: 24px;
    font-weight: normal;
}

.text-page p{
    margin-bottom: 18px;
}

header{
    background:#fff;
    min-height: 2.375em;
    margin-bottom:40px;
    box-shadow: inset 0px -2px 0px 0px #ededed;
}

header a:visited,
header a:link {
    color: #777;
}
header a:hover {
    color: #e2c666;
}
header a:active {
    color: #11223b;
}
header a.selected {
    color: #11223b;
}

header .header-wrap{
    padding:0 10px;
}
header .wrap.valgin{
    height: 2.375em;
}

header .logo-place{
    float:left;
    margin-right:1%;
}

header #logo{
    position:relative;
    display:block;
    width: 270px;
    height: 21px;
    margin:5px;
    padding-right:3.3em;
    text-decoration:none;
    background-repeat: no-repeat;
}
header #logo span.sprite{
    position:absolute;
    top:0;
    right:0;
    width:2.25em;
    height:2.25em;
    background-repeat:no-repeat;
    z-index:2;
}

header #logo span{
    display:block;
}
header #logo span.sub{
    font-size:0.87em;
}
header #logo span.text{
    font-size:1.33em;
}

header .info-place{
    float:left;
}
header .info-place .btn{
    font-size:0.8em;
    display:block;
    line-height: 2.9em;
    height: 100%;
    font-weight: 600;
}

header .social-info{
    float:left;
    padding:0 10px;
}
header .social-info a{
    display:inline-block;
    position:relative;
    vertical-align: bottom;
    font-size:1em;
    line-height: 2.3em;
    height: 100%;
    font-weight: 600;
    color:#000;
    padding:2px 0 0 0;
}
header .social-info:hover{
    background:#f4f4f4;
}
header .social-info a img{
    display:inline-block;
    position:relative;
    vertical-align: middle;
    margin:-4px 0 0 0;
}

header .info-place .wrap.payout-in,
header .info-place .wrap.ballance-in{
    padding-right:0px;
}
header .info-place .wrap.ballance-in{
    background: #f4f4f4;
    border-left:1px solid #ededed;
    box-shadow:0 1px 2px rgba(0, 0, 0, 0.1) inset;
}


footer{
    position: absolute;
    bottom: 0;
    width:100%;
    text-align:center;
}

.user-ctr.ballance{
    padding:0 10px;
    font-size: 1em;
    font-weight: 600;
    height:1.2em;
    color: #0c1728;
    text-align:right;
    min-width: 100px;
    max-width:200px;
}
.user-ctr.ballance .psign{
    font-family: symbol-olymp;
    font-size: 0.9em;
    font-weight: 600;
}

.ballance-label{
    font-size: 0.875em;
    font-weight: 600;
    padding-right:12px;
    color: #777;
}

header .menu-place{
    float:right;
}

.menu-place a{
    position: relative;
    display:block;
    padding-left: 22px;
    height:25px;
    background-repeat: no-repeat;
    text-transform: uppercase;
    text-decoration:none;
    font-weight: 600;
    line-height: 1.5em;
}
.menu-place a.action-top-menu::before{
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    display: block;
    width: 15px;
    height: 12px;
}


.user-place{
    float:right;
    margin-right:3%;
}
.user-place .not-logeed .sprite.icon-reg{
    display:block;
    width:2.4375em;
    height:2em;
    background-repeat:no-repeat;
    background-colo-:#000;
}

.user-place a.action-auth,
.user-place a.action-reg{
    text-transform: uppercase;
    font-size: 1em;
    text-decoration: none;
    font-weight:600;
}
.user-place a.action-auth:hover{

}

.user-menu{
    position:relative;
    line-height: 15px;
    font-weight:600;
}
.user-menu .switcher{
    position: relative;
    height:22px;
    background-repeat:no-repeat;
    text-decoration:none;
}
.user-menu .switcher .user-name{
    color:#000;
}
.user-menu .switcher span.icon-menu{
    position: absolute;
    top: 5px;
    right: -21px;
    height:15px;
    width:15px;
}

.user-menu .user-menu-list-container{
    position:absolute;
    top: 34px;
    right:-54px;
    z-index:100;
    padding:12px;
    width:200px;
    border:1px solid #d3d3d3;
    background:#fff;
}
.user-menu .decor{
    position:absolute;
    top:-10px;
    right:50px;
    z-index:2;
    width:13px;
    height:11px;
    background:url(/assets2/css/images/bg_vert-91b3b53464820c510c9ab8a67406f4e8.png) no-repeat 0 -1653px;
    overflow:hidden;
}
.user-menu .user-menu-list li{
    margin-bottom:6px;
    font-size:18px;
    color:#555;
}
.user-menu .user-menu-list li a{
    color:#33b5e5;
}
.user-menu .user-menu-list ul{
    margin-bottom:24px;
}



#content{
    padding:0 10px;
    padding-bottom: 0;
}
#content .wrap-content{
    padding:0 0 3% 0;
    min-height:200px;
}


.paym-list {
    margin-top: 0.5em;
}

/*----BUTTONS ----*/
.btn{
    border:0;
    border-radius:4px;
    -moz-border-radius:4px;
    -webkit-border-radius:4px;
    overflow: visible;
    color:#333;
    cursor:pointer;
    line-height:normal;
    text-align:center;
    font-size:1em;
    text-decoration:none;
    padding:0.2em 0.6em;
}
.btn.big{
    font-size:1.8em;
    line-height: 1.2em;
    padding:6px 14px 12px 14px;
    font-weight:600;
}
.btn.wide{
    display:block;
    width:100%;
}
.btn.grad-dgrey{
    color:#fff;
}

.btn.btn-hl{
    display: block;
    display: inline-block;
    padding: 0 10px;
    background: #e9cc70;
    border-bottom: 2px solid #a58c4e;
    font-size: 0.875em;
    line-height: 38px;
    text-transform:uppercase;
    font-weight:700;
    border-radius:0;
    color: #fff;
    text-shadow: 0 1px 1px rgba(0,0,0,0.35);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}
.btn.btn-contur{
    display: block;
    display: inline-block;
    padding: 0 10px;
    background: #11223b;
    border: 2px solid #e9cc70;
    font-size: 0.875em;
    line-height: 36px;
    border-radius:0;
    color: #e5c86a;
    font-weight:700;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    text-transform: uppercase;
}
.btn.btn-contur:hover{
    border: none;
    border: 2px solid #e9cc70;
    background: #e9cc70;
    color: #fff;
    font-weight:700;
}
.btn.btn-contur:active{
    border: none;
    border: 2px solid #a58c4e;
    background: #a58c4e;
    color: #fff;
    font-weight:700;
}
.btn.btn-contur.selected{
    border: none;
}
.btn.btn-contur.disable{
    border: 2px solid #d9d7d7;
    background: #b8b8b8;
}

.btn.btn-hl:hover{
    border-bottom: 2px solid #a58c4e;
    background: #fbe08b;
}
.btn.btn-hl:active{
    background: #a58c4e;
    border-bottom: 2px solid  #a58c4e;
}

.btn.btn-hl.disable{
    border-bottom: 2px solid #b8b8b8;
    background: #d9d7d7;
}
/*----/ BUTTONS ----*/



/*---- DIALOG ----*/
.dialog{
    position:fixed;
    top:150px;
    left:50%;
    width: 600px;
    margin: 0 0 0 -180px;
    z-index:100000;
    min-height:100px;
    background: #fff;
    text-align: left;
    border-radius:5px;
}

.dialog a{
    font-size: 0.812em;
    line-height: 1.2em;
}
.dialog label a{
    font-size: 1em;
    border-bottom:1px solid #a58c4e;
}
.dialog .dlg-header{
    padding: 24px 40px 0px 40px;
    margin-bottom: 24px;
    position: relative;
    border-radius:4px 4px 0 0;
    text-align: center;
}
.dialog .dlg-header .left{
    float:left;
}
.dialog .dlg-header .right{
    float:right;
}
.dialog .dlg-header h3{
    display: inline-block;
    padding-right:20px;
    position: relative;
    font-size: 1em;
    text-transform: uppercase;
    color: #232F3C;
    font-weight:400;
    font-size: 14px;
}

.dialog .dlg-header h3 a{
    position: absolute;
    top: 4px;
    left:100%;
    text-transform: none;
    border-bottom:1px solid #a58c4e;
    white-space : nowrap;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}
.dialog .dlg-header h3 a:hover{
    color: #a58c4e;
}

.form .result-msg,
.dialog .dlg-msg{
    display: none;
    position:relative;
    border-radius:4px 4px 0 0;
    color: #fff;
    font-weight:600;
    background:#c8504f;
    font-size:13px;
    padding:5px 10px 5px 5px;
}

.form .result-msg a,
.dialog .dlg-msg a{
    color: #fff;
}
.form.is-msg .result-msg,
.dialog.is-msg .dlg-msg{
    display:block;
}
.msg-error .result-msg,
.msg-error .dlg-msg{
    background: #c8504f;
}
.msg-success .result-msg,
.msg-success .dlg-msg{
    background: #75af5f;
}
.msg-info .result-msg,
.msg-info .dlg-msg{
    background: #aaa;
}
.form .dlg-msg .msg-wrapper,
.form .result-msg .msg-wrapper{
    position: relative;
}
.form .dlg-msg .close-msg,
.form .result-msg .close-msg{
    display: block;
    width: 38px;
    height: 38px;
    position: absolute;
    top: 8px;
    right: 6px;
    text-decoration: none;
    border:0;
    background:url(/assets2/css/img/x-9128d7c34b517e75d427c3db180afb3c.png) no-repeat;
    cursor:pointer;
}
.form .dlg-msg .close-msg:hover,
.form .result-msg .close-msg:hover{
    background:url(/assets2/css/img/x-9128d7c34b517e75d427c3db180afb3c.png) no-repeat;
}

.form .preloader,
.dialog .preloader{
    position: relative;
    display: none;
    text-align: center;
}
.form.is-preloading .preloader,
.dialog.is-preloading .preloader{
    display: block;
}
.form.is-preloading .hide-on-preload,
.dialog.is-preloading .hide-on-preload{
    display:none !important;
}

.dialog .dlg-bottom{
    padding: 24px 40px 40px 40px;
    border-radius:0 0 6px 6px ;
    background: #E4E4E4;
}
.dialog .dlg-buttons{
    text-align: center;
    padding: 24px 40px 40px 40px;
}
.dialog .dlg-content{
    display:block;
    padding: 0 30px 20px;

    overflow: hidden;
}
#dialog_channel .dlg-content{
    display:block;
    padding: 0 20px 24px 20px;
}
.dialog .dlg-content .preloader{
    top: 20px;
}
.dialog .dlg-close,
.block-banner .dlg-close{
    display: block;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 14px;
    height: 14px;
    text-decoration: none;
    z-index: 999;
    background:url(/assets2/css/img/icon_close-d25cc064a3e30e9578f4677bf040952d.svg) no-repeat;
}

.hide-on-preload{
    display:block;
    width:100%;
    overflow: hidden;
}
.form.big textarea.textarea.blur{
    font-size: 1.5em;
    color: #b4b4b4;
}

.dialog .row{
    margin-bottom: 24px;
}
.dialog .row .btn{
    display: block;
}
.dialog .desclaim{
    color: #777;
    font-size: 0.8125em;
}

.user-dialog.dialog{
    width: 405px;
    background: #fcfcfc;
    box-shadow:none;
    left:50%;
    margin:0 0 0 -230px;
}
.user-dialog.dialog .dlg-header .right a{
    display: block;
    font-size: 1.5em;
    line-height: 1.6em;
}

.user-dialog.dialog .dlg-bottom p{
    margin-bottom: 12px;
    color: #333;
}
.user-dialog.dialog label,
.user-dialog.dialog label a
    /*.user-dialog.dialog p a*/{
    color: #999;
}

.user-dialog.dialog.is-preloading .dlg-bottom div,
.user-dialog.dialog.is-preloading .dlg-bottom p,
.user-dialog.dialog.is-preloading .row,
.user-dialog.dialog.is-preloading .dlg-header{
    visibility: hidden;
}
.hide{
    display:none!important;
}
#dialog-overlay{
    position:absolute;
    display:block;
    top:0;
    left:0;
    width:100%;
    padding:0 0 2px 0;
    z-index:1000000;
    background:rgba(0, 0, 0, 0.5);
    filter: alpha(opacity=80);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
}





.margin-s{
    margin-bottom: 12px !important;
}
.margin-s label{
    color:#11223b;
    font-size:0.8125em;
}
.no-margin{
    margin:none !important;
}
/*----/ DIALOG ----*/
/*----UTILS ----*/
/*-pair-*/
/*
.pair{
	margin-bottom:18px;
	}
	.pair .label{
		float:left;
		width:28%;
		margin-right:4%;
		font-size:24px;
		color:#666;
		line-height:27px;
		}
	.pair .field{
		float:left;
		width:68%;
		}
		.pair .field .input{
			width:100%;
			font-size:18px;
			}
*/
/*-input-*/

/*-paging-*/
.paging{
    text-align:center;
}
.paging li{
    display:inline;
    padding:0 6px;
    font-size:16px;
    font-weight: 600;
    color:#ccc;
}
.paging li.prev a,
.paging li.next a{
    text-decoration:none;
}
.paging li a{
    color:#fff;
    font-size:16px;
}
.paging li a:hover{
    color:#fff;
    font-size:16px;
    text-decoration:none;
}
/*-nav inline-*/
.nav-inline li{
    display:inline;
    padding:0 12px;
    border-left-:1px solid #fff;
}
.nav-inline li.first{
    border-left:none;
}
.nav-inline li a{
    color:#fff;
    font-size:0.875em;
}
.nav-inline li a:hover{
    color:#fff;
    font-size:0.875em;
    color:#e6c96c;
}
/*----/ UTIL ----*/

/*--- GRADIENTS ----*/

.btn.grad-dgrey{
    box-shadow:0px 0px 0px 1px #212020;
}

.grad-dgrey{
    background-image:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPgo8bGluZWFyR3JhZGllbnQgaWQ9Imc4NDQiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4MT0iMCUiIHkxPSIwJSIgeDI9IjAlIiB5Mj0iMTAwJSI+CjxzdG9wIHN0b3AtY29sb3I9IiM0MTQxNDEiIG9mZnNldD0iMCIvPjxzdG9wIHN0b3AtY29sb3I9IiMyMTIwMjAiIG9mZnNldD0iMSIvPgo8L2xpbmVhckdyYWRpZW50Pgo8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2c4NDQpIiAvPgo8L3N2Zz4=);
    background: #4f4f4f;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzQxNDE0MSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMyMTIwMjAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #4f4f4f 0%, #181717 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#4f4f4f), color-stop(100%,#181717));
    background: -webkit-linear-gradient(top,  #4f4f4f 0%,#181717 100%);
    background: -o-linear-gradient(top,  #4f4f4f 0%,#181717 100%);
    background: -ms-linear-gradient(top,  #4f4f4f 0%,#181717 100%);
    background: linear-gradient(to bottom,  #4f4f4f 0%,#181717 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4f4f4f', endColorstr='#181717',GradientType=0 );
}
.btn.grad-dgrey:hover{
    background: #4f4f4f; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzRmNGY0ZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxODE3MTciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #4f4f4f 0%, #181717 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#4f4f4f), color-stop(100%,#181717)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #4f4f4f 0%,#181717 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #4f4f4f 0%,#181717 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #4f4f4f 0%,#181717 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #4f4f4f 0%,#181717 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4f4f4f', endColorstr='#181717',GradientType=0 ); /* IE6-8 */
}

.btn.grad-dgrey:active{
    box-shadow:inset 0px 0px 10px 0px #020202;
}
.grad-lgrey{
    background: #f3f3f3;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlOWU5ZTkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #ffffff 0%, #e8e8e8 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#e8e8e8));
    background: -webkit-linear-gradient(top,  #ffffff 0%,#e8e8e8 100%);
    background: -o-linear-gradient(top,  #ffffff 0%,#e8e8e8 100%);
    background: -ms-linear-gradient(top,  #ffffff 0%,#e8e8e8 100%);
    background: linear-gradient(to bottom,  #ffffff 0%,#e8e8e8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e8e8e8',GradientType=0 );
    box-shadow: 0 0 0 1px #d0d0d0;
}
.btn.grad-lgrey{
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.btn.grad-lgrey:hover{
    background: #eeeeee; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNkZWRlZGUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #ffffff 0%, #dedede 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#dedede)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #ffffff 0%,#dedede 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #ffffff 0%,#dedede 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #ffffff 0%,#dedede 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #ffffff 0%,#dedede 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dedede',GradientType=0 ); /* IE6-8 */
    box-shadow: 0 0 0 1px #bebebe;

}
.btn.grad-lgrey:active{
    box-shadow:inset 0px 1px 5px rgba(0,0,0,0.2),0 0 0 1px #bebebe;
}

.grad-green{
    background: #84cf5e; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzg0Y2Y1ZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM0Yjk2MmEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #84cf5e 0%, #4b962a 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#84cf5e), color-stop(100%,#4b962a)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #84cf5e 0%,#4b962a 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #84cf5e 0%,#4b962a 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #84cf5e 0%,#4b962a 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #84cf5e 0%,#4b962a 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#84cf5e', endColorstr='#4b962a',GradientType=0 ); /* IE6-8 */
    box-shadow: 0 0 0 1px #52a42e;
}
.btn.grad-green{
    color:#fff;
    outline: none;
}
.btn.grad-green:hover{
    background: #9ce279; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzljZTI3OSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzYTdmMWMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #9ce279 0%, #3a7f1c 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#9ce279), color-stop(100%,#3a7f1c)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #9ce279 0%,#3a7f1c 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #9ce279 0%,#3a7f1c 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #9ce279 0%,#3a7f1c 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #9ce279 0%,#3a7f1c 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9ce279', endColorstr='#3a7f1c',GradientType=0 ); /* IE6-8 */
    box-shadow: 0 0 0 1px #52a42e;
}
.btn.grad-green:active{
    box-shadow:inset 0px 0px 10px 0px #133901,0 0 0 1px #3a7f1c;
}

.grad-red{
    background: #E46C69;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2U0NmM2OSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNhYTMxMmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #E46C69 0%, #aa312f 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#e46c69), color-stop(100%,#aa312f));
    background: -webkit-linear-gradient(top,  #e46c69 0%,#aa312f 100%);
    background: -o-linear-gradient(top,  #e46c69 0%,#aa312f 100%);
    background: -ms-linear-gradient(top,  #e46c69 0%,#aa312f 100%);
    background: linear-gradient(to bottom,  #e46c69 0%,#aa312f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e46c69', endColorstr='#aa312f',GradientType=0 );
    box-shadow: 0 0 0 1px #ba4240;
}

#dialog_pay .dlg-button .btn:hover span.grad-red,
.btn.grad-red:hover{
    background: #fc7571; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2U4ODk4NyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM5YTFiMWEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #fc7571 0%, #9a1b1a 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#fc7571), color-stop(100%,#9a1b1a)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #fc7571 0%,#9a1b1a 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #fc7571 0%,#9a1b1a 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #fc7571 0%,#9a1b1a 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #fc7571 0%,#9a1b1a 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc7571', endColorstr='#9a1b1a',GradientType=0 ); /* IE6-8 */
    box-shadow: 0 0 0 1px #ba4240;
}
#dialog_pay .dlg-button .btn:active span.grad-red,
.btn.grad-red:active{
    box-shadow:inset 0px 0px 10px 0px #560200,0 0 0 1px #9a1b1a;
}

/*---/ GRADIENTS ----*/


/*----ELEMENTS---*/
.paym-list li{
    float:left;
    margin-right:15px;
    height:34px;
    background-repeat:no-repeat;
}
.paym-list li.last{
    margin:0;
}
.paym-list li.master{width:44px;}
.paym-list li.visa{width:66px;}
.paym-list li.wm{width:28px;}
.paym-list li.qiwi{width:27px;}
.paym-list li.yandex{width:26px;}
.paym-list li.sms{width:35px;}
.paym-list li.paypal{width:79px;}
#btn_auth{
    width:100%;
}

.soc-links a{
    display: block;
    float: left;
    text-decoration: none;
    width: 32px;
    height: 32px;
    background-repeat:no-repeat;
    margin-right: 9px;
    border-radius:4px;
    -moz-border-radius:4px;
    -webkit-border-radius:4px;
}
.sprite{background-repeat:no-repeat;}

.sprite.icon-soc-ellipsis{ background-color: #a0a0a0;}
.sprite.icon-soc-fb{background-color: #3c63bd;}
.sprite.icon-soc-moymir{background-color: #2164ad;}
.sprite.icon-soc-ok{background-color: #f8bc2d;}
.sprite.icon-soc-tw{background-color: #5fd9fa;}
.sprite.icon-soc-vk{background-color: #4b74a1;}
.sprite.icon-soc-ya{background-color: #d83933;}
.sprite.icon-soc-gp{background-color: #d86e47;}


a:hover.sprite.icon-soc-ellipsis  { background-color: #939292;}
a:hover.sprite.icon-soc-fb{background-color: #3359b3;}
a:hover.sprite.icon-soc-moymir{background-color: #195ba2;}
a:hover.sprite.icon-soc-ok{background-color: #edb123;}
a:hover.sprite.icon-soc-tw{background-color: #52ceef;}
a:hover.sprite.icon-soc-vk{background-color: #426b97;}
a:hover.sprite.icon-soc-ya{background-color: #cc2e28;}
a:hover.sprite.icon-soc-gp{background-color: #ce633b;}

a:active.sprite.icon-soc-ellipsis  { background-color: #939292; box-shadow:inset 0 0 7px rgba(0,0,0,0.4);}
a:active.sprite.icon-soc-fb{background-color: #3359b3; box-shadow:inset 0 0 7px rgba(0,0,0,0.4);}
a:active.sprite.icon-soc-moymir{background-color: #195ba2; box-shadow:inset 0 0 7px rgba(0,0,0,0.4);}
a:active.sprite.icon-soc-ok{background-color: #edb123; box-shadow:inset 0 0 7px rgba(0,0,0,0.4);}
a:active.sprite.icon-soc-tw{background-color: #52ceef; box-shadow:inset 0 0 7px rgba(0,0,0,0.4);}
a:active.sprite.icon-soc-vk{background-color: #426b97; box-shadow:inset 0 0 7px rgba(0,0,0,0.4);}
a:active.sprite.icon-soc-ya{background-color: #cc2e28; box-shadow:inset 0 0 7px rgba(0,0,0,0.4);}
a:active.sprite.icon-soc-gp{background-color: #ce633b; box-shadow:inset 0 0 7px rgba(0,0,0,0.4);}

.note-win{
    position: fixed;
    bottom:0;
    right:-1000px;
    width: 305px;
    background: #fcfcfc;
    height: auto;
    z-index: 999;
    text-align:left;
    border:2px solid #e2c666;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}
.note-win .content{
    position: relative;
    padding: 10px;
}
.close-frame{
    position: absolute;
    right:-2px;
    top:-2px;
    width: 16px;
    height: 16px;
    border:2px solid #e2c666;
}
.close-frame:hover{
    border:2px solid #11223b;
}
.note-win .close-link{
    line-height: 1.7em;
    padding-left: 13px;
    margin-left: 2px;
}
.note-win h3{
    margin-bottom:10px;
    font-size: 1em;
    font-weight:600;
    color: #11223b;
}
.note-win li,
.note-win p{
    margin-bottom:10px;
    font-size: 0.8125em;
    color: #11223b;
}
.note-win ul{
    margin-bottom:10px;
}
.note-win li{
    margin-bottom: 0;
}
.note-win p.label{
    color: #fff;
}
.note-win p.result{
    color: #85d05f;
    font-weight:600;
    font-size: 3em;
    line-height: 0.7em;
}
.note-win .ya-share{
    display: block;
    height: 30px;
}
.note-win .sum{
    color: #11223b;
    font-size: 1.7em;
}
.note-win .highlight{
    color: #fff;
}
.note-win .greenlight{
    color: #06b237;
}
.note-win .redlight{
    color: #b21717;
}


#deal_controls .note-win{
    position: static;
    bottom:auto;
    right:auto;
    width: 100%;
    background: #252525;
    border:0;
    box-shadow:none;
}
#deal_controls .note-win .close-link{
    display: none;
}

.graph-label .item.time{
    margin-right:37px;
}

.anim-preloader{
    position:absolute;
    top:0;
    left:0;
    z-index:11;
    width:100%;
}
.anim-preloader div.gwrap{
    margin-:0 auto;
    position:absolute;
    margin:-1em 0 0 -100px;
    width: 250px;
    top:50%;
    left:50%;
    height:2em;
    text-align:left;
}
.anim-preloader span{
    display:block;
}
.anim-preloader .text{
    position:relative;
    padding-left:47px;
    color:#11223b;
    font-size:1em;
    font-weight:600;
    line-height:2em;
}
.anim-preloader .sprite{
    position:absolute;
    top:0;
    left:0;
    width:2.5em;
    height:2.5em;
    background-repeat:no-repeat;
    -webkit-animation: prot 1.1s infinite linear;
    -moz-animation: prot 1.1s infinite linear;
    -o-animation: prot 1.1s infinite linear;
    animation: prot 1.1s infinite linear;
}
.anim-preloader.simple{
    position:static;
}
.anim-preloader.simple .sprite{
    position:static;
}
@-webkit-keyframes prot {
    to {-webkit-transform: rotate(360deg);}
}
@keyframes prot {
    to {transform: rotate(360deg);}
}
@-moz-keyframes prot {
    to {-moz-transform: rotate(360deg);}
}
@-o-keyframes prot {
    to {-o-transform: rotate(360deg);}
}
.paym-list-big{
    text-align:center;
    padding: 20px 0 40px 0;
}
.paym-list-big li{
    display:block;
    display:inline-block;
    height:45px;
    background-repeat:no-repeat;
    margin:0 30px;
}
.paym-list-big li.master{width:63px;}
.paym-list-big li.visa{width:87px;}
.paym-list-big li.wm{width:150px;}
.paym-list-big li.qiwi{width:110px;}
.paym-list-big li.yandex{width:98px;}
.paym-list-big li.sms{width:70px;}
.paym-list-big li.paypal{width:115px;}

.tab-nav{
    margin-bottom: 18px;
    border-bottom:3px solid #b7b7b7;
}
.tab-nav li{
    float: left;
    margin-right: 12px;
}

.tab-nav li.current{
    border-radius:4px 4px 0 0;
    background: #d9d9d9; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIxJSIgc3RvcC1jb2xvcj0iI2Q5ZDlkOSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNiN2I3YjciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #d9d9d9 1%, #b7b7b7 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(1%,#d9d9d9), color-stop(100%,#b7b7b7)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #d9d9d9 1%,#b7b7b7 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #d9d9d9 1%,#b7b7b7 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #d9d9d9 1%,#b7b7b7 100%); /* IE10+ */
    background: linear-gradient(to bottom, #d9d9d9 1%,#b7b7b7 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9d9d9', endColorstr='#b7b7b7',GradientType=0 ); /* IE6-8 */
}
.tab-nav li a{
    display: block;
    text-decoration: none;
    padding: 5px 10px 3px 10px;
    font-size: 1.12em;
}
.tab-nav li a:hover,
.tab-nav li.current a{
    color: #333;
}
/*
.tab .col{
    float: left;
    width: 50%;
    }
*/
.tabs .tab{
    display:block;
}
.tabs.tab-personal .tab#tab_personal{
    display: block;
}
.tabs.tab-payout .tab#tab_payout{
    display: block;
}
.tabs.tab-payout .tab#tab_help{
    display: block;
}
.tabs.tab-payout .tab#tab_statistics{
    display: block;
}
.tabs.tab-recharge .tab#tab_recharge{
    display: block;
}


.tab .banner{
    position:relative;
    height:270px;
}
.tab .video{
    position:relative;
    height:130px;
}
.tab .banner h3 a,
.tab .video h3 a,
.tab .landing h3 a{
    margin:0 5px 0 5px;
}
.tab .banner .column h3,
.tab .video .column h3{
    margin:10px 0 0 63px;
}
.tab .landing h3 {
    margin: 0 0 0 -5px;
}
.tab .banner .column p{
    text-align:right;
    margin:0 15px 0 10px;
}
.tab .banner .column label{
    margin: 0 5px 0 0;
}
.tab .banner .column .input{
    width:78%;
    margin:0 0 5px 0px;
}
.tab .landing .column .input{
    width:94%;
    margin:0 0 5px 0;
}
#tab_promo .input{
    background: #ebebeb;
    height: 20px;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #ffffff;
    border-right: 1px solid #ECECEC;
    border-left: 1px solid #ECECEC;
    font-size: 1em;
    color: #11223b;
}

#cabinet .statistics{
    text-align:center;
}
#stat-tab .btn-contest {
    padding: 10px 30px;
    background: #a963b0;
    font-size: 16px;
    line-height: 26px;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 5px;
    color: #fff;
    box-shadow: 3px 3px 5px -1px #8662a2;
    margin: -10px auto 10px auto;
}
.statistics .platform-type span{
    display:inline-block;
    position:relative;
    vertical-align: middle;
    margin:0;
    font-size:16px;
    border-bottom:1px solid #a58c4e;
    cursor:pointer;
    color:#11223b;
    margin:0 10px;
}
.platform-type span.active{
    font-size:23px;
    border-bottom:none;
    cursor:none;
}

.statistics .nav-menu-stats{
    display:inline-block;
    position:relative;
    vertical-align: middle;
    width:100%;
    padding:0;
    margin:10px 0 0 0;
    text-align: center;
}
.statistics .tab-traders .nav-menu-stats{
    width:auto;
    text-align: left;
    padding:0 0 0 300px;
}
.nav-menu-stats p{
    display:inline-block;
    position:relative;
    vertical-align: middle;
    margin:0;
    font-size:13px;
}
.nav-menu-stats .select{
    padding-right:60px;
    font-size:16px;
    color:#000;
}

.nav-menu-stats p a{
    margin:0 5px;
    text-align: center;
}

.statistics p.title-date{
    display:block;
    position:relative;
    font-size:18px;
    color:#000;
    font-weight:600;
    margin:0 0 10px 0;
}
.statistics p.datepicker{
    display:block;
    position:relative;
    left:0;
    top:10px;
    font-size:14px;
    color:#000;
    font-weight:600;
    margin:0 0 10px 0;
}
.statistics p.datepicker input{
    padding:5px;
    color:#777777;
    margin:20px 0 10px 0;
}
.statistics .datepicker .btn.btn-hl{
    font-size:14px;
    line-height: 2;
    padding:0 30px;
    margin:0 0 20px 0;
}
.statistics p.title-trade{
    display:inline-block;
    position:absolute;
    left:0;
    top:10px;
    font-size:14px;
    color:#000;
    font-weight:600;
}
.nav-menu-stats .choice{
    font-size:16px;
    color:#000;
    margin:0 5px;
}
.statistics a,
#result-payout a{
    color:#000000;
    border-bottom:1px solid #000000;
    text-decoration: none;
    font-weight:500;
}
.statistics a:hover,
.statistics a:active{
    color: #000000;
    border-bottom:1px solid #000000;
    text-decoration: none;
}
.statistics a.num-page:hover,
.statistics a.num-page:active{
    color:#2a475a;
    border-bottom:1px solid #ECECEC;
    text-decoration: none;
}
.statistics  a.none,
.statistics  a.none:hover{
    border:none;
    cursor:default;
    color:#696b70;
}
.statistics p:nth-child(2){
    margin:0 5px 0 0;
}
.statistics table,
#recharge_history table {
    width:100%;
    margin:0;
    border:none;
    color:#696b70;
    font-size:13px;
    text-align:left;
    border-spacing:0;
}

#recharge_history table{
    margin:10px 0;
}
.statistics th,
#recharge_history th{
    color:#2A2D33;
    padding:5px 0 5px 0;
    text-align:left;
    font-weight:400;
}
.statistics th{
    position:relative;
    cursor: default;
}
.statistics th p{
    display:inline-block;
    position: relative;
}
.statistics th p:hover span{
    display:block;
}
.statistics th p span{
    display:none;
    position:absolute;
    width:150px;
    height:auto;
    top:0;
    right:-175px;
    background: #fff;
    padding: 10px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #344050 !important;
    border: 1px solid #ECECEC;
    border-radius: 5px;
    text-align: left;
    z-index:10000;
    text-transform: none;
    white-space: pre-line;
}
.statistics th:last-child p span{
    left:-175px;
}
.statistics th:nth-child(2) p span{
    right:-175px!important;
}

.statistics th{
    text-align:right;
    padding:5px 5px;
}
#recharge_history th{
    padding:5px 20px 5px 5px;
}
#recharge_history td{
    padding:5px 0 5px 0;
    text-align:right;
}
.statistics td{
    text-align:left;
    padding:5px 5px;
}

.statistics td:nth-child(1) span,
.statistics td:nth-child(2) span,
.statistics td:nth-child(3) span{
    display:none;
    position:absolute;
    width:auto;
    top:30px;
    left:40px;
    border:1px solid #1c2530;
    color:#fff!important;
    padding:10px;
    background: #273a4c;
    font-size:14px;
    font-weight:400;
    text-align: left;
    z-index:10000;
    white-space: pre;
}

#recharge_history td{
    padding:5px 20px 5px 0;
}

#recharge_history th:nth-child(2),
#recharge_history td:nth-child(2){
    text-align:center;
}


.statistics tr td .usd{
    color:#696b70;
}

.no-stat{
    position:relative;
    font-size:16px;
    margin:50px auto 0 auto;
    text-align:center;
    font-weight: 700;
    color:#344050;
    max-width:650px;
    min-height: 300px;
    z-index:10;
}

.no-banner{
    display:block;
    position:relative;
    font-size:16px;
    margin:50px auto 0 auto;
    text-align:center;
    font-weight: 700;
    color:#000;
    max-width:650px;
}

.statistics .tab-traders th,
.statistics .tab-traders td{
    text-align:left !important;
}

.statistics a{
    display:inline-block;
    position:relative;
}
.statistics a:after{
    content:attr(data-content);
    display:inline-block;
    position:relative;
    top:0;
    right:0;
    font-size: 12px;
}
.statistics b{
    display: block;
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: rgba(52, 64, 80, 0.50);
    margin-top:2px;
}
.statistics .channel-name b{
    font-weight:400;
    border-bottom:1px solid #FFCD00;
    cursor:pointer;
}
.stat-header .no-stat{
    display:none;
    position:relative;
    width:100%;
    margin:100px auto;
    text-align:center;
}
.statistics .percent,
.country-result .percent{
    display:inline-block!important;
    position: relative;
    color:#696b70;
}
.statistics .percent:after,
.country-result .percent:after{
    content:attr(data-content);
    display:inline-block;
    position:relative;
    top:0;
    right:0;
    font-size: 12px;
}
.statistics .usd{
    display:inline-block;
    position: relative;
}
.statistics .usd:after{
    content: "$";
    display: block;
    position: absolute;
    top:-12px;
    left:0;
    font-size: 12px;
}
.pair-icon.eurusd::before,
.pair-icon.eurusd{background-position:0 0; }
.pair-icon.gbpusd::before,
.pair-icon.gbpusd{background-position:0 -22px; }
.pair-icon.aur::before,
.pair-icon.aur{background-position:0 -42px; }
.pair-icon.oil::before,
.pair-icon.oil{background-position:0 -62px; }
.pair-icon.rndm::before,
.pair-icon.rndm{background-position:0 -83px; }
.pair-icon.usdchf::before,
.pair-icon.usdchf{background-position:0 -103px; }
.pair-icon.usdjpy::before,
.pair-icon.usdjpy{background-position:0 -125px; }
.pair-icon.usdrub::before,
.pair-icon.usdrub{background-position:0 -149px; }
.pair-icon.demo_1::before,
.pair-icon.demo_1{background-position:0 -83px; }
.pair-icon.demo_2::before,
.pair-icon.demo_2{background-position:0 -83px; }

.pair-icon.demo_1:before, .pair-icon.demo_1 {
    background-position: 0 -83px;

}

.menu-place a.action-top-menu:before {
    top: 6px;

}

/*----/ ELEMENTS

input[type="checkbox"]{
    display:none;
}
input[type="checkbox"] + label,
input[type="radio"] + label {
    cursor: pointer;
}
input[type="checkbox"] + label::before,
input[type="radio"] + label::before {
    content: "";
    display: inline-block;
    height: 17px;
    width: 14px;
    margin: 0 0 0 0;
    background-repeat: no-repeat;
}
---*/

nav.nav-main{
    width: 320px;
    background: #11223b;
    position: fixed;
    top:0;
    right: 0;
    height: 100%;
    z-index:999;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

nav.nav-main .close-link{
    display: block;
    position: absolute;
    top:15px;
    right:40px;
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
}
nav.nav-main .nav-wrapper{
    padding: 55px 40px 40px 40px;
    text-align: left;
}
nav.nav-main .btn.btn-contur{
    display:inline-block;
    width:80%;
}
nav.nav-main .btn-list{
    margin-bottom: 24px;
}
nav.nav-main .btn-list li{
    margin-bottom: 12px;
    display:block;
}
nav.nav-main .btn-list li span{
    display:inline-block;
    margin:0 10px 0 -25px;
    width:15px;
    height:15px;

}

nav.nav-main h3.title{
    color: #777;
    text-transform: uppercase;
    font-size:1em;
    text-align: left;
    margin-bottom: 0px;
    padding-bottom:0;
}
nav.nav-main a{
    color: #ccc;
    font-size: 0.775em;
    font-weight: 600;
}
nav.nav-main a:hover{
    color: #e3c768;
}

nav.nav-main ul.list-of-actions{
    margin-bottom: 24px;
}
nav.nav-main .lang-place li{
    display: block;
    display: inline-block;
    margin-right: 25px;

}
nav.nav-main .lang-place li a{
    display: block;
    text-decoration: none;
    width: 30px;
    height: 22px;
}
nav.nav-main .lang-place li a:hover,
nav.nav-main .lang-place li.current a{
    outline: 2px solid #e2c666;
}
nav.nav-main.collapsed{
    right: -320px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

}
footer .paym-title{
    text-transform: uppercase;
    color: #777;
    font-weight: 600;
}
.hide-till-loading{
    display: none !important;
}


.form.user-help{
    margin:30px 0 30px 0;
    clear:both;
}

.form .row{
    margin: 10px 0;
    position:relative;
}
.form .row a{
    font-size:13px;
}
.form .result-msg{
    position:relative;
    border-radius:0;
    margin-bottom: 18px;
}
.form.user-help .result-msg{
    margin:0 425px 10px 0;
}
.form .step-item{
    display: none;
}
.form .step-item.step-all{
    display: block;
}

.form .step-item.step-all.left,
.form .step-item.step-all.right {
    display: inline-block;
    margin:0 40px 18px 0;
}
.form .input {
    background: #fff;
    border: 1px solid #ECECEC;
    outline: none;
    padding: 0 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 12px;
    color: #474747;
    line-height: 42px;
    font-weight: normal;
    font-family: TT Hoves, sans-serif;
    height: 42px;
    text-align:left;
}
.form .input:focus{
    box-shadow:none;
}
select option{
    color: #777;
    padding:0 0 0  10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form .input.write-us,
.form .input.email,
.form .select.topic{
    width:200px;
    margin:0;
}
.form .select.topic{
    width:210px;
}
.form .input.question {
    width:455px;
    height:150px;
    padding:10px;
    resize:none;
}


.form .input value{
    font-size: 0.8125em;
    font-weight: 400;
    color: #999;
}
.form label.label-txt{
    display: block;
    margin-bottom: 9px;
    font-size: 0.8125em;
}
.form .btn{
    margin:0 auto;
    width:100%;
}
.form .step-item .btn{
    margin:0;
    padding:0 40px;
    width:auto;
}
.form.confirm-msg,
.form.get-purse-code{
    padding:0 80px 20px 80px;
    margin:-25px 0 0 0;
}

.tabs{
    position: relative;
    border:2px solid #e2c666;
    min-height: 500px;
}
.tab{
    position: relative;
    padding: 38px;
    min-height: 500px;
}
.tab h3.tab-title{
    position: absolute;
    top:-16px;
    left: 0;
    width: 100%;
    color: #11223b;
    text-align: center;
    font-size: 1.25em;
    font-weight: 600;
    margin-bottom: 18px;
    text-transform: uppercase;
}
.tab h3.tab-title span{
    padding: 0 12px;
    background: #f5f5f5;
}
.tabs .nav-vert{
    position: absolute;
    top: 10px;
    right: -10px;
    z-index: 2;
    padding: 10px 0;
}
.tabs .nav-vert li{
    position: relative;
    margin-bottom: 11px;
    min-height: 32px;
}

.tabs .nav-vert li:first-child{
    margin-top:9px;
}
.tabs .nav-vert li:last-child{
    margin-top:15px;
}
.tabs .nav-vert li:last-child a,
.tabs .nav-vert li:first-child a{
    background: #F4F4F4;
}
.tabs .nav-vert li:last-child{
    margin-bottom:9px;
}
.tabs .nav-vert a{
    display: block;
    position: relative;
    min-height: 32px;
    width: 20px;
}
.tabs .nav-vert li span.decor{
    position: absolute;
    top: 2px;
    right: -7px;
    z-index: 2;
    display: block;
    width: 22px;
    height: 25px;
    border:4px solid #f5f5f5;
}
.tabs .nav-vert li span.decor:hover{
    border:4px solid #fff;
}
.tabs .nav-vert li:hover span.decor{
    border:3px solid #fff;
    top:3px;
    right:-5px;
}
.tabs .nav-vert li span.text{
    display: none;
    padding-left:8px;
    border:2px solid #F4F4F4;
    min-height: 33px;
    position: absolute;
    top: 0px;
    right:-8px;
    border:2px solid #e2c666;
    background: #fff;
    width: 220px;
    z-index: 1;
    line-height: 32px;
    text-align: left;
}

.tabs .nav-vert li span.right-text {
    display: none;
    padding-right:8px;
    border:2px solid #F4F4F4;
    min-height: 33px;
    position: absolute;
    top: 0px;
    left:-5px;
    border:2px solid #e2c666;
    background: #fff;
    width: 220px;
    z-index: 1;
    line-height: 32px;
    text-align: left;
}

.tabs .nav-vert li a:hover span.text,
.tabs .nav-vert li a:hover span.right-text{
    display: block;
}

.tabs .nav-vert li:last-child a span{
    top: -2px;
}
.tabs .nav-vert li:last-child:hover a span.decor{
    top:0;
    margin-top:1px;
}
.tabs .nav-vert li span.text{
    width: auto;
    white-space:nowrap;
    padding-right: 34px;
    font-size:0.8125em;
}

.input-arrows{
    position: relative;
}
.input-arrows .input{
    width: 100%;
}
.input-arrows .arrow{
    position: absolute;
    right: 0;
    top: 8px;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    z-index: 3;
    margin-right: -10px;
}
.input-arrows .arrow.down{
    top: 21px;
}

.paym-tab .input-in .input-arrows{

}
.paym-tab .input-in{
    position: relative;
}
.paym-tab .input-in .sign{
    position: absolute;
    top: 0;
    right: -38px;
    font-family: symbol-olymp;
    font-size: 1.5em;
    line-height: 1.7em;
    font-weight: 400;
}
.pay-btns .sign{
    font-family: symbol-olymp;
    margin-left: -5px;
}

#dialog_recharge{
    width: 700px;
    background: #f5f5f5;
    min-height: 500px;
}

#dialog_recharge .recharge-wrap{
    position: relative;
    padding-left: 250px;
}
#dialog_recharge.is-preloading .preloader{
    top:100px;
}
#dialog_recharge .sidebar{
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;

}
#dialog_recharge .paym-tabs-nav li{
    min-height: 46px;
    border-bottom: 1px solid #edc75f;
}
#dialog_recharge .paym-tabs-nav li.selected{
    background: #fff;
}
#dialog_recharge .paym-tabs-nav li a{
    display: block;
    font-weight: 600;
    color: #f4cd6a;
    text-transform: uppercase;
}
#dialog_recharge .paym-tabs-nav li a span.sprite{
    float: left;
    margin-top: 7px;
    margin-left: 18px;
    width: 44px;
    height: 34px;
}
#dialog_recharge .paym-tabs-nav li a span.sprite.icon-card-min{
    width: 130px;
}
#dialog_recharge .paym-tabs-nav li a span.text{
    float: left;
    margin-top: 3px;
    line-height: 40px;
}
#dialog_recharge .paym-tabs-nav li a:hover{
    color: #b49559;
}
#dialog_recharge .paym-tabs-nav li.selected a{
    color: #11223b;
}
#dialog_recharge .content-area{
    padding: 12px 20% 12px 15%;
    min-height: 500px;
    background: #fff;
}
.paym-tab h3.title{
    color: #11223b;
    font-size: 1.125em;
    font-weight: 600;
    margin-bottom: 20px;
    padding-top: 5px;
    text-align: center;
}
.pay-btns ul{
    text-align: center;
}
.pay-btns li{
    display: inline-block;
    margin-right: 9px;
}
.pay-btns li:last-child{
    margin-right: 0px;
}
.pay-btns .label-txt{
    text-align: center;
}
.pay-btns li label{
    font-size: 1.125em;
    font-weight: 600;
}
.pay-btns li label .sign{
    font-size: 0.9em;
    font-weight: 400;
}
.pay-btns li.selected label{
    color: #11223b;
}
.pay-btns li.selected label .sign{
    color: #777;
}
.paym-tab .btn-in{
    text-align: center;
}
.paym-tab .btn{
    margin: 0 auto;
}

.wrap-content .page-none{
    display:block;
    position:relative;
    text-align:left;
    font-size:16px;
    font-weight: 600;
    padding: 0 0 0 90px;
    margin:100px 0 0 0;
    color:#000;
}
.page-none:before{
    content: "";
    background: url(/assets2/css/images/sprite/bg_404-a7b88010b4d46085760a8e6298b06ef7.png);
    display: block;
    height: 42px;
    left: 0;
    position: absolute;
    top: 3px;
    vertical-align: top;
    width: 61px;
}
.page-none p a{
    line-height:1.5em;
    color:#0099cc;
    border-bottom:1px solid #0099cc;
}

.crosspage-content {
    background: #11223b;
    padding:20px;
    text-align: center;
}
.crosspage-wrap {
    margin:0 auto;
    max-width: 966px;
}
.crosspage-content .col {
    float: left;
    width: 25%;
}
.crosspage-content .btn {
    font-weight: 700;
    text-transform: uppercase;
    width: 176px;
}

.txt-message{
    margin:0 20px 20px 20px ;
    font-size:13px;
}

.row.new-pass-in a{
    border-bottom:1px solid #a58c4e;
    margin:0 !important;
}


.col.left {
    width:48%;
    float: left;
    margin-right: 2%;
}
.col.right  {
    width:45%;
    float: right;
}

.col ul{
    margin:0 0 20px 0;
}

.listBox {
    display:block;
    position:relative;
}
.slct {
    display:block;
    position:relative;
    background: #ebebeb;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #ffffff;
    border-right: 1px solid #ECECEC;
    border-left: 1px solid #ECECEC;
    padding: 8px 0;
    width: 100%;
    font-size: 15px;
    font-weight:600;
    color: #777;
    -webkit-appearance: none;
    background-image: url(/assets2/css/images/cabinet/bg_decrement-fc098f278c6d259a315c5a13eaf161f7.png);
    background-position: right center;
    background-repeat: no-repeat;
    line-height: 1em;
    /* for FF */
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    /* for IE */
    -ms-appearance: none;
    appearance: none!important;
    margin:0;
}

.slct:hover{
    background-image: url(/assets2/css/images/cabinet/bg_decrement_hover-b3fcdc72d8e4154d7135c7f16b9bda0f.png);
}

.slct::-ms-expand {
    display: none;
}
.listBox p{
    margin:0 0 0 10px;
    font-size:15px !important;
}
.listBox .main{
    font-size:15px;
}
.listBox .small{
    font-size:11px;
    font-weight:400;
}
.listBox ul{
    display:none;
    position:absolute;
    background: #ebebeb;
    top:45px;
    left:0;
    width: 100%;
    z-index:10000;
}
.listBox li{
    vertical-align: middle;
    background: #ebebeb;
    border-top: 1px solid #ffffff;
    color: #777;
    font-size: 16px;
    font-weight:600;
    line-height: 1em;
    padding: 8px 0;
    cursor:pointer;
}
.listBox li:hover{
    background:#1e90ff;
}
.columns-info{
    display:inline-block;
    position:relative;
    width:100vw;
    font-size:16px;
    margin:0;
}
.columns-info.error-403{
    position:relative;
    margin:20px 0 0 0;
}
.columns-info .sprite{
    display:inline-block;
    position:relative;
    vertical-align: middle;
    line-height:1.05;
    color:#2a2d33;
    margin:0 20px 0 0 ;
    text-align: left;
}
.sprite .span-big{
    font-size:34px;
    font-weight:600;
    margin:0 !important;
    display: inline-block!important;
}
.sprite .span-small{
    font-size:17px;
    text-align: center;
    display: inline-block!important;
    margin:0 !important;
}

.columns-info .error-text{
    display:inline-block;
    position:relative;
    vertical-align: middle;
    color:#2a2d33;
    font-size:20px;
    line-height:1.3;
    margin:0;
    padding:0;
}
.error-text a{
    font-size:20px;
}
.error-text a:hover{
    color:#0097a7;
}
.checkbox-area .checker {
    margin-top: 0 !important;
}
.checkbox-area .check-input-label {
    display: inline-block;
    position: relative;
}



.remind-pass .faq-form-btn{
	margin: 15px 0;
    width:auto;
}

.faq-form.support{
    margin-bottom: 15px;
    width: 100%!important;
    max-width: 100%!important;
}

.faq-form p.error{
    background:#FF003D;
    padding:5px;
    margin:0 0 5px 0;
}
.faq-form p.success{
    background:#63E270;
    padding:5px;
    margin:20px 0 25px 0;
}

@media only screen  and (max-width: 800px),
only screen and (max-device-width: 800px){
    .statistics th p span{
        left:-125px;
        width:100px;
        font-size:12px;
    }
    .statistics th:nth-child(2) p span{
        left:85px;
    }

    .statistics th:nth-child(3) p span{
        left:105px;
    }
    .statistics th:last-child p span {
        left: -125px;
    }
}

@media only screen  and (max-width: 400px),
only screen and (max-device-width: 400px){
    .statistics th:nth-child(2) p span{
        left:85px;
    }
    .statistics th:nth-child(3) p span{
        left:-125px;
    }
    .dialog{
        width: 300px;
        margin-left:-150px;
    }

}
@media only screen  and (max-width: 900px) {
    .dialog{
        width: 500px;
        margin: 0 0 0 -130px;
    }
}


@media only screen  and (max-width: 800px) {
    .dialog{
        width: 94%;
        margin: 0 auto;
        left: auto;
    }
    .promo-body-item-link-text{
        width: auto !important;
    }
}


