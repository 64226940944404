.jq-selectbox__trigger-arrow,
.change-lang .icon-down,
.columns-left-nav-list-item-link .fa-icon-caret-down,
.faq-item-list-item .arrow,
.support .item-ticket .item-title-ticket .arrow,
.pay-system .arrow{
	display: inline-block;
	position: relative;
	width: 16px;
	height: 16px;
	vertical-align: middle;
}

.columns-left-nav-list-item-link .fa-icon-caret-down{
	position: absolute;
	right:30px;
}

.change-lang .icon-down {
	top: 13px !important;
}

.jq-selectbox__trigger-arrow {
	top:0;
	left: 0;
}

.faq-item-list-item .arrow,
.support .item-ticket .item-title-ticket .arrow,
.pay-system .arrow {
	position: absolute;
	top: 24px;
	right: 24px;
}

.support .item-ticket .item-title-ticket .arrow {
	right: 8px;
}

.jq-selectbox__trigger-arrow:before, .jq-selectbox__trigger-arrow:after,
.change-lang .icon-down:before, .change-lang .icon-down:after,
.columns-left-nav-list-item-link .fa-icon-caret-down:before, .columns-left-nav-list-item-link .fa-icon-caret-down:after,
.faq-item-list-item .arrow:before, .faq-item-list-item .arrow:after,
.support .item-ticket .item-title-ticket .arrow:before, .support .item-ticket .item-title-ticket .arrow:after,
.pay-system .arrow:before, .pay-system .arrow:after {
	content: '';
	position: absolute;
	width: 12px;
	height: 2px;
	border-radius: 2px;
	transition: 0.25s ease-out all;
	top: 10px;
	margin-top: 0px;
	background: #AAAAAA;
}
.jq-selectbox__trigger-arrow:before,
.change-lang .icon-down:before,
.columns-left-nav-list-item-link .fa-icon-caret-down:before,
.faq-item-list-item .arrow:before,
.support .item-ticket .item-title-ticket .arrow:before,
.pay-system .arrow:before {
	transform-origin: right center;
	transform: rotateZ(45deg);
	right: 50%;
}
.jq-selectbox__trigger-arrow:after,
.change-lang .icon-down:after,
.columns-left-nav-list-item-link .fa-icon-caret-down:after,
.faq-item-list-item .arrow:after,
.support .item-ticket .item-title-ticket .arrow:after,
.pay-system .arrow:after {
	transform-origin: left center;
	transform: rotateZ(-45deg);
	left: 50%;
	margin-left: -1px;
}
.opened .jq-selectbox__trigger-arrow:before, .opened .jq-selectbox__trigger-arrow:after,
.change-lang.open-list .icon-down:before, .change-lang.open-list .icon-down:after,
.columns-left-nav-list-item-link.columns-left-nav-list-item-link--open .fa-icon-caret-down:before, .columns-left-nav-list-item-link.columns-left-nav-list-item-link--open .fa-icon-caret-down:after,
.faq-item-list-item.active .arrow:before, .faq-item-list-item.active .arrow:after,
.support .item-ticket.open .item-title-ticket .arrow:before, .support .item-ticket.open .item-title-ticket .arrow:after,
.pay-system.active .arrow:before, .pay-system.active .arrow:after {
	top: 0%;
}
.opened .jq-selectbox__trigger-arrow:before,
.change-lang.open-list .icon-down:before,
.columns-left-nav-list-item-link.columns-left-nav-list-item-link--open .fa-icon-caret-down:before,
.faq-item-list-item.active .arrow:before,
.support .item-ticket.open .item-title-ticket .arrow:before,
.pay-system.active .arrow:before {
	transform: rotateZ(-45deg);
}
.opened .jq-selectbox__trigger-arrow:after,
.change-lang.open-list .icon-down:after,
.columns-left-nav-list-item-link.columns-left-nav-list-item-link--open .fa-icon-caret-down:after,
.faq-item-list-item.active .arrow:after,
.support .item-ticket.open .item-title-ticket .arrow:after,
.pay-system.active .arrow:after {
	transform: rotateZ(45deg);
}

@media only screen and (max-width: 800px) {
	.columns-left-nav-list-item-link .fa-icon-caret-down{
		right:10px;
	}
}